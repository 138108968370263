import React from "react";
import { SecondaryButton } from "..";
import "./style.scss";
const IsiBukuTamu = ({ fillFormRegister, user_id }) => {
  return (
    <div>
      {user_id === null && (
        <>
          <div
            className="container-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              bottom: "calc(0px + 39%)",
              position: "absolute",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                background: "#fff",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ padding: 4, fontSize: 12 }}>
                Selamat datang di Culture Fest ASN 2021, <br /> Silahkan untuk
                mengisi buku tamu digital.
              </div>
              <SecondaryButton
                className="bukutamuwebsite"
                label={"Isi Buku Tamu"}
                onClick={fillFormRegister}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IsiBukuTamu;

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from "react";
import { getMobileOperatingSystem } from "../../lib/utils";

import BackgroundSound1 from "../../assets/mp3/ASN AWARD MASTER - 121021.mp3";
import BackgroundSound2 from "../../assets/mp3/ASN AWARD MUSIC MASTER - 121021.mp3";
import {
  Background,
  Button,
  InputEmail,
  InputNip,
  InputPhone,
  InputText,
  InputTextArea,
} from "../atoms";
import {
  Drawer,
  ModalConfirmation,
  ModalConfirmationTwoLabel,
  ModalForm,
  ModalFormCustom,
  ModalTwoLayout,
  TopMenu,
} from "../molecules";

// logo / icon / image
import LogoPANRB from "../../assets/img/logo-pan-rb.png";
import LogoSuccess from "../../assets/img/logo-success.png";
import LogoTerkirim from "../../assets/img/logo-terkirim.png";
import ListActive from "../../assets/img/list-active.png";
import ListNonActive from "../../assets/img/list-non-active.png";
import ListActiveMobile from "../../assets/img/list-active-mobile.png";
import ListNonActiveMobile from "../../assets/img/list-non-active-mobile.png";
import PosterLogoBottom from "../../assets/img/poster-logo-bottom.png";
import ModalIconExhibitionHall from "../../assets/img/modal-icon-exhibition-hall.png";
import ModalIconZoominar from "../../assets/img/modal-icon-zoominar.png";
import LogoSliderLeft from "../../assets/img/logo-slider-left.png";
import LogoSliderRight from "../../assets/img/logo-slider-right.png";
import IconVideo from "../../assets/img/icon-video.png";

import {
  ExhibitionDetailMobile,
  ExhibitionDetailWebsite,
  ExhibitionMobile,
  ExhibitionWebsite,
  HomepageMobile,
  HomepageWebsite,
  ZoominarMobile,
  ZoominarWebsite,
} from "../organism";

import axios from "axios";

import {
  fetchHomepage,
  fetchRegistrationAttribute,
  fetchContactUsAttribute,
  fetchRegistration,
  fetchExhibitionList,
  fetchMasukkanAttribute,
  fetchZoominar,
  fetchAskBoothAttribute,
} from "../../redux";
import { connect } from "react-redux";
import Formsy from "formsy-react";
import "./exhibition.scss";
import ReactPlayer from "react-player";
// import AnimationGroupOne from "../../assets/img/animation-group-one.gif";
// import AnimationGroupTwo from "../../assets/img/animation-group-two.gif";
// import AnimationGroupThree from "../../assets/img/animation-group-three.gif";
// import AnimationGroupFour from "../../assets/img/animation-group-four.gif";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButtonContactUS = this.disableButtonContactUS.bind(this);
    this.enableButtonContactUs = this.enableButtonContactUs.bind(this);
    this.state = {
      stage: "Homepage",
      audio1: new Audio(BackgroundSound1),
      audio2: new Audio(BackgroundSound2),
      device: getMobileOperatingSystem(),
      openMenu: false,
      registrationModal: false,
      user_id: localStorage.getItem("user_id"),
      videoKiri: false,
      videoKanan: false,
      confirmationModal: false,
      confirmationModalLabel: "Registrasi Berhasil",
      confirmationModalMsg: `Selamat datang di PANRBFestival,
Silakan menuju Beranda untuk mengikuti Acara`,
      sendingConfirmationModal: false,
      transformationModal: false,
      transformationId: "",
      detailExhibition: [],
      detailExhibitionChildren: [],
      contactUsModal: false,
      masukkanModal: false,
      playlistModal: false,
      linkShowing: [],
      playingVideoOnBoothModal: false,
      titleModal: "Daftar Video",
      footerModal: false,
      playingVideoOnZoominar: false,
      audioNumber: true,
      openDetailList: false,
      askBooth: false,
      confirmationAskBooth: false,
      sound: false,
      activeMenu: 0,

      videoList: [],
      videoPlayed: 0,
      template_config: [],
      start_song_on_first_click: true,
    };
  }

  async componentDidMount() {
    await this.userVisit();
    await this.props.fetchHomepage();
    await this.props.fetchRegistrationAttribute();
    await this.props.fetchContactUsAttribute();
    await this.props.fetchExhibitionList();
    await this.props.fetchMasukkanAttribute();
    await this.props.fetchZoominar();
    await this.props.fetchAskBoothAttribute();
    await this.fetchConfig();

    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
  }

  openDrawerSidebar = () => {
    this.setState({
      openMenu: !this.state.openMenu,
      playlistModal: false,
    });
  };
  playingVideoZoom = () => {
    this.setState({
      playingVideoOnZoominar: true,
    });
    this.pauseSound();
  };

  pauseVideoZoom = () => {
    this.setState({
      playingVideoOnZoominar: false,
    });
    this.playSound();
  };

  playSound = () => {
    if (this.state.audioNumber) {
      this.state.audio1.play();
      this.setState({
        sound: true,
      });
    } else {
      this.state.audio2.play();
      this.setState({
        sound: true,
      });
    }
  };

  pauseSound = () => {
    if (this.state.audioNumber) {
      this.state.audio1.pause();
      this.setState({
        sound: false,
      });
    } else {
      this.state.audio2.pause();
      this.setState({
        sound: false,
      });
    }
  };

  pauseSoundAndPlay = () => {
    if (this.state.audioNumber) {
      this.state.audio1.pause();
      this.state.audio2.play();
      this.setState({
        audioNumber: !this.state.audioNumber,
      });
    } else {
      this.state.audio2.pause();
      this.state.audio1.play();
      this.setState({
        audioNumber: !this.state.audioNumber,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.audio1.currentTime === this.state.audio1.duration) {
      this.changeSong();
    }
    if (prevState.audio2.currentTime === this.state.audio2.duration) {
      this.changeSong();
    }
  }

  changeSong = async () => {
    await this.pauseSoundAndPlay();
  };

  changeStateInstantion = (id) => {
    this.setState({
      transformationId: id,
    });
  };

  openAskBooth = () => {
    this.setState({
      askBooth: !this.state.askBooth,
    });
  };

  exhibitionTend = () => {
    const { transformationId } = this.state;
    const { exhibitionList } = this.props;
    let index = exhibitionList[0].findIndex((x) => x.id === transformationId);

    return (
      <>
        <div onClick={() => this.fetchDetailExhibition(transformationId)}>
          <div class="container-center-mobile">
            <img
              src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
              alt={"no desc"}
              style={{ width: "80%" }}
            />
          </div>
          <div class="container-center-mobile-logo-exhibition">
            <img
              className="logo-instansi"
              src={exhibitionList[0][index].logo}
              alt="logo"
            />
          </div>
          <div class="container-center-mobile-description">
            {exhibitionList[0][index].name}
          </div>
        </div>
      </>
    );
  };

  onChangeSound = () => {
    if (this.state.sound) {
      this.pauseSound();
    } else {
      this.playSound();
    }
  };

  clickButtonToPlaySound = () => {
    this.pauseSound();
    this.setState({
      playingVideoOnBoothModal: true,
    });
  };

  clickButtonToPauseSound = () => {
    this.playSound();
    this.setState({
      playingVideoOnBoothModal: false,
    });
  };

  goToZoomByLink = () => {
    const { zoominar } = this.props;
    window.open(zoominar.link_zoom.url, "_blank");
  };

  goToZoomExhibitionBoothByLink = () => {
    const { detailExhibition } = this.state;
    window.open(detailExhibition.link_zoom, "_blank");
  };

  render() {
    const {
      stage,
      device,
      openMenu,
      user_id,
      detailExhibition,
      detailExhibitionChildren,
      footerModal,
    } = this.state;
    const { homepage, exhibitionList, zoominar } = this.props;
    console.log("STAGE =>", this.state.stage);

    return (
      <div>
        {device === "OTHERS" ? (
          <Background device={device} stage={stage}>
            {stage === "Homepage" ? (
              <>
                <TopMenu
                  device={device}
                  logoCompany={LogoPANRB}
                  eventButton={() => this.setState({ openMenu: !openMenu })}
                />
                <HomepageWebsite
                  user_id={user_id}
                  homepage={homepage}
                  fillFormRegister={() => this.registrationModal()}
                  goToExhibitionHall={() => this.goToExhibitionHallModal()}
                  goToZoominar={() => this.goToZoominarModal()}
                  footerModal={footerModal}
                  contactUsModal={() => this.contactUsModal()}
                  onChangeFooterModal={() =>
                    this.setState({ footerModal: !footerModal })
                  }
                  onChangeSound={() => this.onChangeSound()}
                  sound={this.state.sound}
                  template_config={this.state.template_config?.song_control}
                />
              </>
            ) : stage === "Zoominar" ? (
              <>
                <TopMenu
                  device={device}
                  logoCompany={LogoPANRB}
                  eventButton={() => this.setState({ openMenu: !openMenu })}
                />
                <ZoominarWebsite
                  user_id={user_id}
                  homepage={homepage}
                  stageBeranda={() => this.goToExhibitionToHome()}
                  stageExhibition={() => this.goToZoominarToExhibition()}
                  contactUsModal={() => this.contactUsModal()}
                  footerModal={footerModal}
                  zoominar={zoominar}
                  onChangeFooterModal={() =>
                    this.setState({ footerModal: !footerModal })
                  }
                  onChangeSound={() => this.onChangeSound()}
                  sound={this.state.sound}
                  template_config={this.state.template_config?.song_control}
                >
                  {this.state.videoList.length > 0 && (
                    <div className="zoominar-container">
                      <div className="video-playlist">
                        <div className="playlist-title">Daftar Video</div>
                        <div className="playlist-video">
                          {this.state.videoList.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                this.setState({ videoPlayed: index })
                              }
                              className={`video-item ${
                                index === this.state.videoPlayed ? "active" : ""
                              }`}
                            >
                              <div className="video-title">{item.name}</div>
                              <div className="video-img">
                                <img
                                  src={this.getYoutubeEmbedImg(item.url)}
                                  alt=""
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{ minHeight: "12px", minWidth: "12px" }}
                      ></div>
                      <div className="zoominar-content">
                        <div id="video-frame">
                          <div className="video-frame-wrapper">
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              url={this.getYoutubeEmbedCode(
                                this.state.videoList[this.state.videoPlayed].url
                              )}
                              loop={false}
                              playing={true}
                              controls={true}
                              onEnded={() => {
                                if (
                                  this.state.videoPlayed + 1 >=
                                  this.state.videoList.length
                                )
                                  this.setState({ videoPlayed: 0 });
                                else
                                  this.setState({
                                    videoPlayed: this.state.videoPlayed + 1,
                                  });
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ minHeight: "12px", minWidth: "12px" }}
                        ></div>
                        <div
                          style={{ minHeight: "12px", minWidth: "176px" }}
                        ></div>
                      </div>
                    </div>
                  )}
                </ZoominarWebsite>
              </>
            ) : stage === "ModalBooth" ? (
              <>
                <ModalTwoLayout
                  title={this.state.titleModal}
                  closeModal={() => this.closeModalBooth()}
                >
                  <div className="modal-two-layout-sidebar">
                    {this.state.detailBooth
                      ? this.state.detailBooth.map((n, index) => {
                          return (
                            <div
                              style={{
                                background:
                                  this.state.activeMenu === index
                                    ? "#E0E0E0"
                                    : "",
                              }}
                              onClick={() =>
                                this.clickActiveBoothArticle(n, index)
                              }
                              className="modal-two-layout-sidebar-items"
                            >
                              <div
                                style={{ cursor: "pointer", paddingRight: 16 }}
                              >
                                {n.name}
                              </div>
                              <div className="lihat">Lihat</div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="modal-two-layout-content">
                    {this.linkShowing()}
                  </div>
                </ModalTwoLayout>
              </>
            ) : stage === "Exhibition" ? (
              <>
                <TopMenu
                  device={device}
                  logoCompany={LogoPANRB}
                  eventButton={() => this.setState({ openMenu: !openMenu })}
                />
                <ExhibitionWebsite
                  user_id={user_id}
                  homepage={homepage}
                  stage={stage}
                  contactUsModal={() => this.contactUsModal()}
                  stageBeranda={() => this.goToExhibitionToHome()}
                  stageZoominar={() => this.goToExhibitionToZoominar()}
                  footerModal={footerModal}
                  onChangeFooterModal={() =>
                    this.setState({ footerModal: !footerModal })
                  }
                  onChangeSound={() => this.onChangeSound()}
                  sound={this.state.sound}
                  template_config={this.state.template_config?.song_control}
                >
                  <div className="list-empat">
                    <div className="item-exhibition-hall-top">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][0]
                            ? exhibitionList[0][0].description
                            : "no description"
                        }
                      />

                      <span
                        className="item-exhibition-hall-top-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][0] ? exhibitionList[0][0] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][0]
                              ? exhibitionList[0][0].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-top-description-hidden">
                        {exhibitionList[0][0]
                          ? exhibitionList[0][0].name
                          : "no name"}
                      </span>
                    </div>

                    <div className="item-exhibition-hall-top">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][1]
                            ? exhibitionList[0][1].description
                            : "no description"
                        }
                      />
                      <span
                        className="item-exhibition-hall-top-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][1] ? exhibitionList[0][1] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][1]
                              ? exhibitionList[0][1].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-top-description-hidden">
                        {exhibitionList[0][1]
                          ? exhibitionList[0][1].name
                          : "no name"}
                      </span>
                    </div>

                    <div className="item-exhibition-hall-top">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][2]
                            ? exhibitionList[0][2].description
                            : "no description"
                        }
                      />
                      <span
                        className="item-exhibition-hall-top-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][2] ? exhibitionList[0][2] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][2]
                              ? exhibitionList[0][2].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-top-description-hidden">
                        {exhibitionList[0][2]
                          ? exhibitionList[0][2].name
                          : "no name"}
                      </span>
                    </div>

                    <div className="item-exhibition-hall-top">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][3]
                            ? exhibitionList[0][3].description
                            : "no description"
                        }
                      />
                      <span
                        className="item-exhibition-hall-top-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][3] ? exhibitionList[0][3] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][3]
                              ? exhibitionList[0][3].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-top-description-hidden">
                        {exhibitionList[0][3]
                          ? exhibitionList[0][3].name
                          : "no name"}
                      </span>
                    </div>
                  </div>
                  <div className="list-dua">
                    <div className="item-exhibition-hall-bottom">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][4]
                            ? exhibitionList[0][4].description
                            : "no description"
                        }
                      />
                      <span
                        className="item-exhibition-hall-bottom-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][4] ? exhibitionList[0][4] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][4]
                              ? exhibitionList[0][4].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-bottom-description-hidden">
                        {exhibitionList[0][4]
                          ? exhibitionList[0][4].name
                          : "no name"}
                      </span>
                    </div>

                    <div className="item-exhibition-hall-bottom">
                      <img
                        src={"https://i.ibb.co/hHBD6js/Rectangle-612.png"}
                        alt={
                          exhibitionList[0][5]
                            ? exhibitionList[0][5].description
                            : "no description"
                        }
                      />
                      <span
                        className="item-exhibition-hall-bottom-name"
                        onClick={() =>
                          this.showTransformationModal(
                            exhibitionList[0][5] ? exhibitionList[0][5] : ""
                          )
                        }
                      >
                        <img
                          className="item-exhibition-hall-top-name-instansi"
                          src={
                            exhibitionList[0][5]
                              ? exhibitionList[0][5].logo
                              : ""
                          }
                          alt="logo"
                        />
                      </span>
                      <span className="item-exhibition-hall-bottom-description-hidden">
                        {exhibitionList[0][5]
                          ? exhibitionList[0][5].name
                          : "no name"}
                      </span>
                    </div>
                  </div>
                </ExhibitionWebsite>
              </>
            ) : stage === "ExhibitionDetail" ? (
              <>
                <TopMenu
                  device={device}
                  logoCompany={LogoPANRB}
                  eventButton={() => this.setState({ openMenu: !openMenu })}
                />
                <ExhibitionDetailWebsite
                  stageBack={() =>
                    this.setState({
                      stage: "Exhibition",
                    })
                  }
                >
                  <div className="detail-exhibition-name">
                    <img
                      src={this.state.transformationLogo}
                      alt={detailExhibition.name}
                      className="detail-exhibition-name-logo"
                    />
                    {detailExhibition.name}
                  </div>
                  <div className="detail-exhibition-description">
                    {detailExhibition.description}
                  </div>
                  <div className="container-poster">
                    {detailExhibitionChildren
                      ? detailExhibitionChildren.map((n, index) => {
                          return (
                            <div
                              key={index}
                              className="poster-box"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.fetchDetailBooth(n.id, n.name)
                              }
                            >
                              <div className="name">{n.name}</div>
                              <div>
                                <img
                                  className="icon"
                                  src={n.url}
                                  alt={n.name}
                                />
                              </div>
                              <div>
                                <img
                                  className="poster-logo-bottom"
                                  src={PosterLogoBottom}
                                  alt="poster"
                                />
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>

                  <div className="bottom-container-exhibition-detail">
                    <div className="info-exhibition-detail">
                      <span className="info">
                        Booth akan live via zoom pada: <br />
                        {detailExhibition.date_zoom}
                      </span>
                    </div>
                    <div
                      className="info-exhibition-detail"
                      onClick={() => this.goToZoomExhibitionBoothByLink()}
                    >
                      <span className="info-button-zoom">
                        <img src={IconVideo} alt="logo" className="icon-zoom" />{" "}
                        Bergabung di zoom
                      </span>
                    </div>
                    <div
                      className="info-exhibition-detail"
                      onClick={() => this.openAskBooth()}
                    >
                      <span className="info-button-tanya">
                        Tanya Pengelola Booth ?
                      </span>
                    </div>
                  </div>

                  <div className="arrow-navigation">
                    <img
                      className="slider-left"
                      src={LogoSliderLeft}
                      alt="slider-left"
                      onClick={() => this.fetchDetailExhibitionByArrowLeft()}
                    />
                    <img
                      className="slider-right"
                      src={LogoSliderRight}
                      alt="slider-right"
                      onClick={() => this.fetchDetailExhibitionByArrowRight()}
                    />
                  </div>

                  <div className="list-exhibition-website">
                    {exhibitionList
                      ? exhibitionList[0].map((n, index) => {
                          if (this.state.transformationId === n.id) {
                            return (
                              <div
                                key={index}
                                src={ListActive}
                                alt="list"
                                className="list-slider-website"
                              />
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                src={ListNonActive}
                                className="list-slider-website-non"
                                alt="list"
                                onClick={() => this.fetchDetailExhibition(n.id)}
                              />
                            );
                          }
                        })
                      : ""}
                  </div>
                </ExhibitionDetailWebsite>
              </>
            ) : (
              ""
            )}
          </Background>
        ) : (
          <>
            <Background device={device} stage={stage}>
              <Drawer
                eventButton={() => this.openDrawerSidebar()}
                show={this.state.openMenu}
                eventMenu1={() => this.registrationModal()}
                eventMenu2={() => this.goToZoominarToExhibitionMobile()}
                eventMenu3={() => this.goToExhibitionToZoominar()}
                eventMenu4={() => this.contactUsModal()}
                user_id={user_id}
              />
              {stage === "Homepage" ? (
                <>
                  <TopMenu
                    device={device}
                    logoCompany={LogoPANRB}
                    eventButton={() => this.openDrawerSidebar()}
                  />
                  <HomepageMobile
                    user_id={user_id}
                    homepage={homepage}
                    fillFormRegister={() => this.registrationModal()}
                    toZoominar={() => this.goToExhibitionToZoominar()}
                    toExhibitionHall={() =>
                      this.goToZoominarToExhibitionMobile()
                    }
                    footerModal={footerModal}
                    contactUsModal={() => this.contactUsModal()}
                    onChangeFooterModal={() =>
                      this.setState({ footerModal: !footerModal })
                    }
                    onChangeSound={() => this.onChangeSound()}
                    sound={this.state.sound}
                    template_config={this.state.template_config?.song_control}
                  />
                </>
              ) : stage === "Zoominar" ? (
                <>
                  {" "}
                  <TopMenu
                    device={device}
                    logoCompany={LogoPANRB}
                    eventButton={() => this.openDrawerSidebar()}
                  />
                  <ZoominarMobile
                    user_id={user_id}
                    homepage={homepage}
                    fillFormRegister={() => this.registrationModal()}
                    toZoominar={() => this.goToZoominarToExhibitionMobile()}
                    toHome={() => this.goToExhibitionToHome()}
                    zoominar={zoominar}
                    footerModal={footerModal}
                    contactUsModal={() => this.contactUsModal()}
                    onChangeFooterModal={() =>
                      this.setState({ footerModal: !footerModal })
                    }
                    onChangeSound={() => this.onChangeSound()}
                    sound={this.state.sound}
                    template_config={this.state.template_config?.song_control}
                    onClickDaftarVideo={this.playlistModal}
                  >
                    {this.state.videoList.length > 0 && (
                      <div className="zoominar-container-mobile">
                        <div className="zoominar-content">
                          <div id="video-frame">
                            <div className="video-frame-wrapper">
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                url={this.getYoutubeEmbedCode(
                                  this.state.videoList[this.state.videoPlayed]
                                    .url
                                )}
                                loop={false}
                                playing={true}
                                controls={true}
                                onEnded={() => {
                                  if (
                                    this.state.videoPlayed + 1 >=
                                    this.state.videoList.length
                                  )
                                    this.setState({ videoPlayed: 0 });
                                  else
                                    this.setState({
                                      videoPlayed: this.state.videoPlayed + 1,
                                    });
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{ minHeight: "12px", minWidth: "12px" }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </ZoominarMobile>
                </>
              ) : stage === "Exhibition" ? (
                <>
                  <TopMenu
                    device={device}
                    logoCompany={LogoPANRB}
                    eventButton={() => this.openDrawerSidebar()}
                  />
                  <ExhibitionMobile
                    user_id={user_id}
                    homepage={homepage}
                    fillFormRegister={() => this.registrationModal()}
                    toZoominar={() => this.goToExhibitionToZoominar()}
                    toHome={() => this.goToExhibitionToHome()}
                    footerModal={footerModal}
                    contactUsModal={() => this.contactUsModal()}
                    onChangeFooterModal={() =>
                      this.setState({ footerModal: !footerModal })
                    }
                    onChangeSound={() => this.onChangeSound()}
                    sound={this.state.sound}
                    template_config={this.state.template_config?.song_control}
                  >
                    {this.exhibitionTend()}

                    <div className="arrow-navigation-exhibition-mobile">
                      <img
                        className="slider-left"
                        src={LogoSliderLeft}
                        alt="slider-left"
                        onClick={() => this.changeExhibitionHallLeft()}
                      />
                      <img
                        className="slider-right"
                        src={LogoSliderRight}
                        alt="slider-right"
                        onClick={() => this.changeExhibitionHallRight()}
                      />
                    </div>

                    <div className="list-exhibition-mobile">
                      {exhibitionList
                        ? exhibitionList[0].map((n, index) => {
                            if (this.state.transformationId === n.id) {
                              return (
                                <div
                                  key={index}
                                  src={ListActiveMobile}
                                  alt="list"
                                  className="list-slider-mobile"
                                />
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  src={ListNonActiveMobile}
                                  className="list-slider-mobile-non"
                                  alt="list"
                                  onClick={() =>
                                    this.changeStateInstantion(n.id)
                                  }
                                />
                              );
                            }
                          })
                        : ""}
                    </div>
                  </ExhibitionMobile>
                </>
              ) : stage === "ExhibitionDetail" ? (
                <>
                  <TopMenu
                    device={device}
                    logoCompany={LogoPANRB}
                    eventButton={() => this.setState({ openMenu: !openMenu })}
                  />
                  <ExhibitionDetailMobile
                    user_id={user_id}
                    homepage={homepage}
                    goBack={() =>
                      this.setState({
                        stage: "Exhibition",
                      })
                    }
                    detailExhibition={detailExhibition}
                    detailExhibitionChildren={detailExhibitionChildren}
                    fillFormRegister={() => this.registrationModal()}
                    footerModal={footerModal}
                    contactUsModal={() => this.contactUsModal()}
                    onChangeFooterModal={() =>
                      this.setState({ footerModal: !footerModal })
                    }
                    date_exhibition={detailExhibition.date_zoom}
                    openAskBooth={() => this.openAskBooth()}
                    goToZoomExhibitionBoothByLink={() =>
                      this.goToZoomExhibitionBoothByLink()
                    }
                    onChangeSound={() => this.onChangeSound()}
                    sound={this.state.sound}
                    template_config={this.state.template_config?.song_control}
                  >
                    <div className="tengah">
                      {detailExhibitionChildren.map((n, index) => {
                        return (
                          <div
                            key={index}
                            className="button-logo-and-text-mobile"
                            onClick={() => this.fetchDetailExhibitionItem(n)}
                          >
                            <div className="button-logo-and-text-icon-mobile">
                              <img
                                src={n.url}
                                alt="icon"
                                className="icon-exhibition-hall-mobile"
                              />
                            </div>
                            <div>{n.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </ExhibitionDetailMobile>
                </>
              ) : stage === "ExhibitionDetailModal" ? (
                <div className="container-exhibition-detail-booth-detail">
                  <div
                    className="header"
                    onClick={() =>
                      this.setState({
                        stage: "ExhibitionDetail",
                        openDetailList: false,
                      })
                    }
                  >
                    <div className="header-name">
                      {this.state.nameDetail || "-"}
                    </div>
                    <div className="header-close">x</div>
                  </div>
                  {this.state.openDetailList ? (
                    <>
                      <div className="content">
                        <div
                          className="list"
                          onClick={() =>
                            this.setState({
                              openDetailList: false,
                            })
                          }
                        >
                          <div
                            className="item"
                            style={{ color: "#B42B2D", fontWeight: "bold" }}
                          >
                            {" < "} Daftar {this.state.nameDetail}
                          </div>
                        </div>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                          }}
                        >
                          {this.state.detailListDetail.name}
                        </div>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                          }}
                        >
                          {this.linkShowingMobile()}
                          {/* {this.state.nameDetail === "Gambar dan Video" ? (
                            <>
                              {this.state.detailListDetail.url.includes(
                                "youtube"
                              ) && (
                                <>
                                  <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={this.state.detailListDetail.url}
                                    playing={
                                      this.state.playingVideoOnBoothModal
                                    }
                                    loop={true}
                                  />
                                  <button
                                    className="playingVideoOnBoothModalMobile"
                                    onClick={
                                      this.state.playingVideoOnBoothModal
                                        ? () => this.clickButtonToPauseSound()
                                        : () => this.clickButtonToPlaySound()
                                    }
                                  >
                                    {this.state.playingVideoOnBoothModal
                                      ? "Pause"
                                      : "Play"}
                                  </button>
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "mp4"
                              ) && (
                                <>
                                  <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={this.state.detailListDetail.url}
                                    playing={
                                      this.state.playingVideoOnBoothModal
                                    }
                                    loop={true}
                                  />
                                  <button
                                    className="playingVideoOnBoothModal"
                                    onClick={
                                      this.state.playingVideoOnBoothModal
                                        ? () => this.clickButtonToPauseSound()
                                        : () => this.clickButtonToPlaySound()
                                    }
                                  >
                                    {this.state.playingVideoOnBoothModal
                                      ? "Pause"
                                      : "Play"}
                                  </button>
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "jpg"
                              ) && (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "jpeg"
                              ) && (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "png"
                              ) && (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "jfif"
                              ) && (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "cloud"
                              ) && (
                                <>
                                  <iframe
                                    width="100%"
                                    height="500"
                                    src={this.state.detailListDetail.url}
                                  />
                                </>
                              )}
                              {this.state.detailListDetail.url.includes(
                                "instagram"
                              ) && (
                                <>
                                  <iframe
                                    width="100%"
                                    height="500"
                                    src={this.state.detailListDetail.url}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.detailListDetail.url.includes(
                                "instagram"
                              ) ? (
                                <>
                                  <iframe
                                    width="100%"
                                    height="500"
                                    src={this.state.detailListDetail.url}
                                  />
                                </>
                              ) : this.state.detailListDetail.url.includes(
                                  "jpg"
                                ) ? (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              ) : this.state.detailListDetail.url.includes(
                                  "png"
                                ) ? (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              ) : this.state.detailListDetail.url.includes(
                                  "jfif"
                                ) ? (
                                <>
                                  <img
                                    src={this.state.detailListDetail.url}
                                    width="100%"
                                    height="100%"
                                    alt="gambar"
                                  />
                                </>
                              ) : (
                                <>
                                  <iframe
                                    width="100%"
                                    height="500"
                                    src={this.state.detailListDetail.url}
                                  />
                                </>
                              )}
                            </>
                          )} */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="content">
                        {this.state.detailList.map((n, index) => {
                          return (
                            <div
                              key={index}
                              className="list"
                              onClick={() =>
                                this.changeStateExhibitionDetailBooth(n)
                              }
                            >
                              <div className="item">{n.name}</div>
                              <div className="action">Lihat</div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </Background>
          </>
        )}

        <ModalForm
          title="Tanya Pengelola Booth"
          show={this.state.askBooth}
          closeWhenOutScreen={() => this.openAskBooth()}
          closeModal={() => this.openAskBooth()}
          width={device === "OTHERS" ? "35%" : "80%"}
        >
          <Formsy
            onValidSubmit={this.submitAskBooth}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            {this.props.askBooth
              ? this.props.askBooth.map((n, index) => {
                  if (n.input_text === "text") {
                    return (
                      <InputText
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else if (n.input_text === "textarea") {
                    return (
                      <InputTextArea
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else if (n.input_text === "email") {
                    return (
                      <InputEmail
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations="isEmail"
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else {
                    return (
                      <InputPhone
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  }
                })
              : ""}

            <Button
              label="Kirim"
              className={
                !this.state.canSubmit ? "lg-button-non-active" : "lg-button"
              }
              disabled={!this.state.canSubmit}
            />
          </Formsy>
        </ModalForm>

        <ModalConfirmationTwoLabel
          icon={LogoSuccess}
          show={this.state.transformationModal}
          label={this.state.transformationLabel}
          label2={this.state.transformationLabelTwo}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({
              transformationModal: !this.state.transformationModal,
            })
          }
          msg={
            "Saksikan semua video yang berkaitan dengan Stan Transformasi ini , silakan lihat dan pelajari seluruh dokumentasi yang terdapat di dalamnya"
          }
          labelButton={`Lanjut ke ${this.state.transformationLabel}`}
          event={() =>
            this.goToDetailTransformation(this.state.transformationId)
          }
          width={device === "OTHERS" ? "35%" : "80%"}
        />
        <ModalForm
          title="Buku Tamu Digital"
          show={this.state.registrationModal}
          closeModal={() => this.registrationModal()}
          width={device === "OTHERS" ? "35%" : "80%"}
          closeWhenOutScreen={() => this.registrationModal()}
        >
          <Formsy
            onValidSubmit={this.submit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            {this.props.registrationAttribute
              ? this.props.registrationAttribute.map((n, index) => {
                  if (n.input_text === "text") {
                    if (n.name === "nip") {
                      return (
                        <InputNip
                          key={index}
                          label={n.label}
                          name={n.name}
                          validations={{
                            minLength: 18,
                          }}
                          validationErrors={{
                            minLength: `${n.label} harus terdiri dari 18 Karakter`,
                          }}
                          required
                        />
                      );
                    } else {
                      return (
                        <InputText
                          key={index}
                          label={n.label}
                          name={n.name}
                          validationError={`${n.label} harap diisi`}
                          required
                        />
                      );
                    }
                  } else if (n.input_text === "textarea") {
                    return (
                      <InputTextArea
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else if (n.input_text === "email") {
                    return (
                      <InputEmail
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations="isEmail"
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else {
                    return (
                      <InputPhone
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations={{
                          maxLength: 13,
                        }}
                        validationErrors={{
                          maxLength: `${n.label} maksimal terdiri dari 13 Karakter`,
                        }}
                        required
                      />
                    );
                  }
                })
              : ""}
            <Button
              label="Submit"
              className={
                !this.state.canSubmit ? "lg-button-non-active" : "lg-button"
              }
              disabled={!this.state.canSubmit}
            />
          </Formsy>
        </ModalForm>
        <ModalForm
          title="Tanya Kami"
          show={this.state.contactUsModal}
          closeModal={() => this.contactUsModal()}
          width={device === "OTHERS" ? "35%" : "80%"}
          closeWhenOutScreen={() => this.contactUsModal()}
        >
          <Formsy
            onValidSubmit={this.submitContactUs}
            onValid={this.enableButtonContactUs}
            onInvalid={this.disableButtonContactUS}
          >
            {this.props.contactUsAttribute
              ? this.props.contactUsAttribute.map((n, index) => {
                  if (n.input_text === "text") {
                    if (n.name === "nip") {
                      return (
                        <InputNip
                          key={index}
                          label={n.label}
                          name={n.name}
                          validations={{
                            minLength: 18,
                          }}
                          validationErrors={{
                            minLength: `${n.label} harus terdiri dari 18 Karakter`,
                          }}
                          required
                        />
                      );
                    } else {
                      return (
                        <InputText
                          key={index}
                          label={n.label}
                          name={n.name}
                          validationError={`${n.label} harap diisi`}
                          required
                        />
                      );
                    }
                  } else if (n.input_text === "textarea") {
                    return (
                      <InputTextArea
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else if (n.input_text === "email") {
                    return (
                      <InputEmail
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations="isEmail"
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else {
                    return (
                      <InputPhone
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations={{
                          maxLength: 13,
                        }}
                        validationErrors={{
                          maxLength: `${n.label} maksimal terdiri dari 13 Karakter`,
                        }}
                        required
                      />
                    );
                  }
                })
              : ""}

            <Button
              label="Kirim"
              className={
                !this.state.canSubmit ? "lg-button-non-active" : "lg-button"
              }
              disabled={!this.state.canSubmit}
            />
          </Formsy>
        </ModalForm>
        <ModalFormCustom
          title=""
          show={this.state.playlistModal}
          closeModal={() => this.playlistModal()}
          closeWhenOutScreen={() =>
            this.setState({
              playlistModal: !this.state.playlistModal,
            })
          }
          width={device === "OTHERS" ? "35%" : "80%"}
        >
          <div className="zoominar-container-mobile no-padding-top">
            <div className="video-playlist">
              <div className="playlist-title">Daftar Video</div>
              <div className="playlist-video">
                {this.state.videoList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      this.setState({
                        videoPlayed: index,
                        playlistModal: !this.state.playlistModal,
                      });
                    }}
                    className={`video-item ${
                      index === this.state.videoPlayed ? "active" : ""
                    }`}
                  >
                    <div className="video-title">{item.name}</div>
                    <div className="video-img">
                      <img src={this.getYoutubeEmbedImg(item.url)} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalFormCustom>
        <ModalForm
          title="Ada masukan?"
          closeWhenOutScreen={() =>
            this.setState({
              masukkanModal: false,
            })
          }
          show={this.state.masukkanModal}
          closeModal={() =>
            this.setState({
              masukkanModal: false,
            })
          }
          width={device === "OTHERS" ? "35%" : "80%"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#4F4F4F",
              fontSize: 12,
              lineHeight: "19px",
            }}
          >
            Sedang dalam pembahasan, Silakan tinggalkan pesan, beri masukkan
            atau saran
          </div>
          <Formsy
            onValidSubmit={this.submitMasukkan}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            {this.props.masukkanAttribute
              ? this.props.masukkanAttribute.map((n, index) => {
                  if (n.input_text === "text") {
                    if (n.name === "nip") {
                      return (
                        <InputNip
                          key={index}
                          label={n.label}
                          name={n.name}
                          validations={{
                            minLength: 18,
                          }}
                          validationErrors={{
                            minLength: `${n.label} harus terdiri dari 18 Karakter`,
                          }}
                          required
                        />
                      );
                    } else {
                      return (
                        <InputText
                          key={index}
                          label={n.label}
                          name={n.name}
                          validationError={`${n.label} harap diisi`}
                          required
                        />
                      );
                    }
                  } else if (n.input_text === "textarea") {
                    return (
                      <InputTextArea
                        key={index}
                        label={n.label}
                        name={n.name}
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else if (n.input_text === "email") {
                    return (
                      <InputEmail
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations="isEmail"
                        validationError={`${n.label} harap diisi`}
                        required
                      />
                    );
                  } else {
                    return (
                      <InputPhone
                        key={index}
                        label={n.label}
                        name={n.name}
                        validations={{
                          maxLength: 13,
                        }}
                        validationErrors={{
                          maxLength: `${n.label} maksimal terdiri dari 13 Karakter`,
                        }}
                        required
                      />
                    );
                  }
                })
              : ""}
            <Button
              label="Kirim"
              className={
                !this.state.canSubmit ? "lg-button-non-active" : "lg-button"
              }
              disabled={!this.state.canSubmit}
            />
          </Formsy>
        </ModalForm>
        {/* <ModalLoading show={this.state.loading} /> */}

        <ModalConfirmation
          icon={LogoSuccess}
          show={this.state.confirmationAskBooth}
          label={"Berhasil Dikirim"}
          msg={`Pesan Anda berhasil terkirim,
balasan pesan selanjutnya akan dikirimkan ke
email atau kontak Anda`}
          labelButton="Oke, Mengerti"
          event={() => this.setState({ confirmationAskBooth: false })}
          width={device === "OTHERS" ? "35%" : "80%"}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({
              confirmationAskBooth: !this.state.confirmationAskBooth,
            })
          }
        />

        <ModalConfirmation
          icon={LogoSuccess}
          show={this.state.confirmationModal}
          label={this.state.confirmationModalLabel}
          msg={this.state.confirmationModalMsg}
          labelButton="Ke Beranda"
          event={() => this.homeReload()}
          width={device === "OTHERS" ? "35%" : "80%"}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({ confirmationModal: !this.state.confirmationModal })
          }
        />
        <ModalConfirmation
          icon={ModalIconZoominar}
          show={this.state.confirmationGoToZoominar}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({
              confirmationGoToZoominar: !this.state.confirmationGoToZoominar,
            })
          }
          label={"Zoominar"}
          msg={this.msgZoominar()}
          labelButton="Oke, Lanjut ke Zoominar"
          event={() => this.goToZoominar()}
          width={device === "OTHERS" ? "35%" : "80%"}
        />

        <ModalConfirmation
          icon={ModalIconExhibitionHall}
          show={this.state.confirmationGoToExhibitionHall}
          label={"Exhibition Hall"}
          msg={this.msgExhibitionHall()}
          labelButton="Oke, Lanjut ke Exhibition Hall"
          event={() => this.goToExhibitionHall()}
          width={device === "OTHERS" ? "35%" : "80%"}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({
              confirmationGoToExhibitionHall:
                !this.state.confirmationGoToExhibitionHall,
            })
          }
        />
        <ModalConfirmation
          icon={LogoTerkirim}
          show={this.state.sendingConfirmationModal}
          label={this.state.confirmationModalLabel}
          msg={this.state.confirmationModalMsg}
          labelButton="Oke, Mengerti"
          event={() => this.setState({ sendingConfirmationModal: false })}
          width={device === "OTHERS" ? "35%" : "80%"}
          checkCloseModal={true}
          closeModal={() =>
            this.setState({
              sendingConfirmationModal: !this.state.sendingConfirmationModal,
            })
          }
        />
      </div>
    );
  }

  msgExhibitionHall = () => {
    return (
      <div>
        <p>
          Dalam hall ini anda dapat melihat berbagai kegiatan/dokumen terkait
          Budaya Kerja ASN BerAKHLAK dan Employer Branding Bangga Melayani
          Bangsa di beberapa Instansi Pemerintah.
        </p>
        <div style={{ textAlign: "left" }}>
          <ol>
            <li>Kementerian Koordinator bidang Kemaritiman dan Investasi</li>
            <li>Pemerintah Provinsi Gorontalo</li>
            <li>Badan Standardisasi Nasional</li>
            <li>Pemerintah Kota Makassar</li>
            <li>Lembaga Administrasi Negara</li>
            <li>Pemerintah Provinsi Jawa Timur</li>
          </ol>
        </div>
      </div>
    );
  };

  msgZoominar = () => {
    return (
      <div>
        <p>
          Dalam hall ini anda dapat menyaksikan berbagai video yang berkaitan
          dengan Budaya Kerja ASN BerAKHLAK dan Employer Branding Bangga
          Melayani Bangsa
        </p>
      </div>
    );
  };

  linkShowingMobile = () => {
    if (this.state.detailListDetail.url.includes("youtube")) {
      return (
        <>
          <ReactPlayer
            width="100%"
            height="100%"
            url={this.state.detailListDetail.url}
            playing={this.state.playingVideoOnBoothModal}
            loop={true}
          />
          <button
            className="playingVideoOnBoothModalMobile"
            onClick={
              this.state.playingVideoOnBoothModal
                ? () => this.clickButtonToPauseSound()
                : () => this.clickButtonToPlaySound()
            }
          >
            {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
          </button>
        </>
      );
    } else if (this.state.detailListDetail.url.includes("mp4")) {
      return (
        <>
          <ReactPlayer
            width="100%"
            height="100%"
            url={this.state.detailListDetail.url}
            playing={this.state.playingVideoOnBoothModal}
            loop={true}
          />
          <button
            className="playingVideoOnBoothModal"
            onClick={
              this.state.playingVideoOnBoothModal
                ? () => this.clickButtonToPauseSound()
                : () => this.clickButtonToPlaySound()
            }
          >
            {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
          </button>
        </>
      );
    } else if (this.state.detailListDetail.url.includes("jpg")) {
      return (
        <>
          <img
            src={this.state.detailListDetail.url}
            width="100%"
            height="100%"
            alt="gambar"
          />
        </>
      );
    } else if (this.state.detailListDetail.url.includes("jpeg")) {
      return (
        <>
          <img
            src={this.state.detailListDetail.url}
            width="100%"
            height="100%"
            alt="gambar"
          />
        </>
      );
    } else if (this.state.detailListDetail.url.includes("png")) {
      return (
        <>
          <img
            src={this.state.detailListDetail.url}
            width="100%"
            height="100%"
            alt="gambar"
          />
        </>
      );
    } else if (this.state.detailListDetail.url.includes("jfif")) {
      return (
        <>
          <img
            src={this.state.detailListDetail.url}
            width="100%"
            height="100%"
            alt="gambar"
          />
        </>
      );
    } else if (this.state.detailListDetail.url.includes("cloud")) {
      return (
        <>
          <iframe
            width="100%"
            height="500"
            src={this.state.detailListDetail.url}
          />
        </>
      );
    } else if (this.state.detailListDetail.url.includes("instagram")) {
      return (
        <>
          <iframe
            width="100%"
            height="500"
            src={this.state.detailListDetail.url}
          />
        </>
      );
    } else {
      return (
        <>
          <iframe
            width="100%"
            height="500"
            src={this.state.detailListDetail.url}
          />
        </>
      );
    }
  };

  linkShowing = () => {
    const { linkShowing, titleModal } = this.state;

    if (linkShowing?.url.includes("youtube")) {
      console.log("include Youtube");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="">
            <ReactPlayer
              width="100%"
              height="70vh"
              url={linkShowing.url}
              playing={this.state.playingVideoOnBoothModal}
              loop={true}
            />
            <button
              style={{ marginTop: 8 }}
              className="playingVideoOnBoothModal"
              onClick={
                this.state.playingVideoOnBoothModal
                  ? () => this.clickButtonToPauseSound()
                  : () => this.clickButtonToPlaySound()
              }
            >
              {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
            </button>
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("mp4")) {
      console.log("include mp4");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="">
            <ReactPlayer
              width="100%"
              height="70vh"
              url={linkShowing.url}
              playing={this.state.playingVideoOnBoothModal}
              loop={true}
            />
            <button
              style={{ marginTop: 8 }}
              className="playingVideoOnBoothModal"
              onClick={
                this.state.playingVideoOnBoothModal
                  ? () => this.clickButtonToPauseSound()
                  : () => this.clickButtonToPlaySound()
              }
            >
              {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
            </button>
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("jpg")) {
      console.log("include jpg");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <img
              alt={linkShowing.name}
              width="100%"
              height="500"
              src={linkShowing.url}
            />
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("png")) {
      console.log("include png");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <img
              alt={linkShowing.name}
              width="100%"
              height="500"
              src={linkShowing.url}
            />
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("jfif")) {
      console.log("include png");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <img
              alt={linkShowing.name}
              width="100%"
              height="500"
              src={linkShowing.url}
            />
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("cloud")) {
      console.log("include cloud");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <iframe width="100%" height="500" src={linkShowing.url} />
          </div>
        </div>
      );
    } else if (linkShowing?.url.includes("instagram")) {
      console.log("include instagram");
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <iframe width="100%" height="500" src={linkShowing.url} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
          <div className="dokumen">
            <iframe width="100%" height="500" src={linkShowing.url} />
          </div>
        </div>
      );
    }

    // if (titleModal === "Video") {
    //   return (
    //     <div>
    //       <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //       <div>
    //         <ReactPlayer
    //           className="react-player"
    //           url={linkShowing.url}
    //           playing={this.state.playingVideoOnBoothModal}
    //           loop={true}
    //           style={{ zIndex: 9999 }}
    //         />
    //         <div
    //           onClick={
    //             this.state.playingVideoOnBoothModal
    //               ? () => this.clickButtonToPauseSound()
    //               : () => this.clickButtonToPlaySound()
    //           }
    //         >
    //           <button className="playingVideoOnBoothModal">
    //             {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // } else if (titleModal === "Gambar dan Video") {
    //   if (linkShowing?.url.includes("youtube")) {
    //     console.log("include Youtube");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="">
    //           <ReactPlayer
    //             width="100%"
    //             height="70vh"
    //             url={linkShowing.url}
    //             playing={this.state.playingVideoOnBoothModal}
    //             loop={true}
    //           />
    //           <button
    //             style={{ marginTop: 8 }}
    //             className="playingVideoOnBoothModal"
    //             onClick={
    //               this.state.playingVideoOnBoothModal
    //                 ? () => this.clickButtonToPauseSound()
    //                 : () => this.clickButtonToPlaySound()
    //             }
    //           >
    //             {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
    //           </button>
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("mp4")) {
    //     console.log("include mp4");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="">
    //           <ReactPlayer
    //             width="100%"
    //             height="70vh"
    //             url={linkShowing.url}
    //             playing={this.state.playingVideoOnBoothModal}
    //             loop={true}
    //           />
    //           <button
    //             style={{ marginTop: 8 }}
    //             className="playingVideoOnBoothModal"
    //             onClick={
    //               this.state.playingVideoOnBoothModal
    //                 ? () => this.clickButtonToPauseSound()
    //                 : () => this.clickButtonToPlaySound()
    //             }
    //           >
    //             {this.state.playingVideoOnBoothModal ? "Pause" : "Play"}
    //           </button>
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("jpg")) {
    //     console.log("include jpg");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <img
    //             alt={linkShowing.name}
    //             width="100%"
    //             height="500"
    //             src={linkShowing.url}
    //           />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("png")) {
    //     console.log("include png");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <img
    //             alt={linkShowing.name}
    //             width="100%"
    //             height="500"
    //             src={linkShowing.url}
    //           />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("jfif")) {
    //     console.log("include png");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <img
    //             alt={linkShowing.name}
    //             width="100%"
    //             height="500"
    //             src={linkShowing.url}
    //           />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("cloud")) {
    //     console.log("include cloud");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("instagram")) {
    //     console.log("include instagram");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   }
    // } else if (titleModal === "Dokumen") {
    //   if (linkShowing?.url.includes("cloud")) {
    //     console.log("include cloud");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("instagram")) {
    //     console.log("include instagram");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   }
    // } else {
    //   if (linkShowing?.url.includes("cloud")) {
    //     console.log("include cloud");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("instagram")) {
    //     console.log("include instagram");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing.url} />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("jpg")) {
    //     console.log("include jpg");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <img
    //             alt={linkShowing.name}
    //             width="100%"
    //             height="500"
    //             src={linkShowing.url}
    //           />
    //         </div>
    //       </div>
    //     );
    //   } else if (linkShowing?.url.includes("png")) {
    //     console.log("include png");
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing.name}</div>
    //         <div className="dokumen">
    //           <img
    //             alt={linkShowing.name}
    //             width="100%"
    //             height="500"
    //             src={linkShowing.url}
    //           />
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div>
    //         <div style={{ fontWeight: "bold" }}>{linkShowing?.name}</div>
    //         <div className="dokumen">
    //           <iframe width="100%" height="500" src={linkShowing?.url} />
    //         </div>
    //       </div>
    //     );
    //   }
    // }
  };

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButtonContactUS() {
    this.setState({ canSubmit: false });
  }

  enableButtonContactUs() {
    this.setState({ canSubmit: true });
  }

  // ASK BOOTH
  fetchConfig = () => {
    axios
      .get(`https://api.panrb.digitalnative.id/api/v1/template-config`)
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          console.log(response.data);
          this.setState({
            template_config: response.data.data.template_config,
          });
        } else {
          this.setState({
            template_config: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          template_config: [],
        });
      });
  };

  submitAskBooth = async (model) => {
    const { detailExhibition } = this.state;
    this.setState({ loading: true, askBooth: false });
    model.email_booth = detailExhibition.email;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ASKBOOTH_POST}`,
        model
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            loading: false,
            confirmationAskBooth: true,
            askBooth: false,
          });
        } else {
          this.setState({
            loading: false,
            confirmationAskBooth: false,
            askBooth: false,
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
        });
      });
  };
  // REGISTRATION
  submit = async (model) => {
    this.setState({ loading: true, registrationModal: false });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REGISTRATION}`,
        model
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          localStorage.setItem("user_id", response.data.data.user_id);
          this.setState({
            registrationModal: false,
            loading: false,
            confirmationModal: true,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
        });
      });
  };

  submitContactUs = (model) => {
    this.setState({ loading: true, contactUsModal: false });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTACT_US}`,
        model
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            confirmationModalLabel: "Berhasil Dikirim",
            confirmationModalMsg: `Pesan Anda berhasil terkirim,
balasan pesan selanjutnya akan dikirimkan ke
email atau kontak Anda`,
            sendingConfirmationModal: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
        });
      });
  };

  submitMasukkan = (model) => {
    this.setState({ loading: true, masukkanModal: false });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_MASUKKAN}`,
        model
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            confirmationModalLabel: "Berhasil Dikirim",
            confirmationModalMsg: `Pesan Anda berhasil terkirim,
balasan pesan selanjutnya akan dikirimkan ke
email atau kontak Anda`,
            sendingConfirmationModal: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
        });
      });
  };

  userVisit = () => {
    const { user_id } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_USER_VISIT}`,
        {
          user_id: user_id === null ? "" : Number(user_id),
        }
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
        } else {
        }
      })
      .catch((errors) => {});
  };

  userVisitToHome = () => {
    const { user_id } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_USER_VISIT}`,
        {
          user_id: user_id === null ? "" : Number(user_id),
        }
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.props.fetchHomepage();
          this.setState({
            stage: "Homepage",
          });
        } else {
        }
      })
      .catch((errors) => {});
  };

  userVisitZoominar = () => {
    const { user_id } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_USER_VISIT}`,
        {
          user_id: user_id === null ? "" : Number(user_id),
        }
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.props.fetchHomepage();
          this.fetchVideoList();
          this.setState({
            stage: "Zoominar",
          });
        } else {
        }
      })
      .catch((errors) => {});
  };

  userVisitExhibition = () => {
    const { user_id } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_USER_VISIT}`,
        {
          user_id: user_id === null ? "" : Number(user_id),
        }
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.props.fetchHomepage();
          this.setState({
            stage: "Exhibition",
          });
        } else {
        }
      })
      .catch((errors) => {});
  };

  goToExhibitionToHome = () => {
    this.playSound();
    this.userVisitToHome();
  };

  goToExhibitionToZoominar = () => {
    const { user_id } = this.state;
    if (user_id === null) {
      alert("Mohon mengisi buku tamu terlebih dahulu");
    } else {
      this.pauseSound();
      this.userVisitZoominar();
    }
  };

  goToZoominarToExhibition = () => {
    this.playSound();
    this.userVisitExhibition();
  };

  goToZoominarToExhibitionMobile = () => {
    const { user_id } = this.state;
    if (user_id === null) {
      alert("Mohon mengisi buku tamu terlebih dahulu");
    } else {
      this.playSound();
      this.userVisitExhibition();
      this.setState({
        transformationId: this.props.exhibitionList[0][0].id,
      });
    }
  };

  goToDetailTransformation = (id) => {
    this.setState({
      transformationModal: false,
    });
    this.fetchDetailExhibition(id);
    this.playSound();
    this.userVisit();
    this.props.fetchHomepage();
  };

  fetchDetailExhibition = (id) => {
    this.setState({
      loading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DETAIL_EXHIBITION}${id}`
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          console.log("ini baru", response.data.data);
          let index = this.props.exhibitionList[0].findIndex(
            (x) => x.id === response.data.data.detail.id
          );
          console.log("______________", this.props.exhibitionList[0][index]);
          this.setState({
            loading: false,
            stage: "ExhibitionDetail",
            detailExhibition: response.data.data.detail,
            detailExhibitionChildren: response.data.data.children,
            transformationId: id,
            transformationLogo: this.props.exhibitionList[0][index].logo,
          });
        } else {
          this.setState({
            loading: false,
            detailExhibition: [],
            detailExhibitionChildren: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          detailExhibition: [],
          detailExhibitionChildren: [],
        });
      });
  };

  fetchDetailExhibitionItem = (n) => {
    this.setState({
      loading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DETAIL_BOOTH_BY_ID}${n.id}`
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            loading: false,
            stage: "ExhibitionDetailModal",
            detailList: response.data.data,
            nameDetail: n.name,
          });
        } else {
          this.setState({
            loading: false,
            detailList: [],
            nameDetail: "",
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          detailList: [],
          nameDetail: "",
        });
      });
  };

  changeStateExhibitionDetailBooth = (n) => {
    this.setState({
      detailListDetail: n,
      openDetailList: true,
    });
  };

  changeExhibitionHallLeft = () => {
    const { transformationId } = this.state;
    const { exhibitionList } = this.props;

    let index = exhibitionList[0].findIndex((x) => x.id === transformationId);
    let id;
    if (index === 0) {
      let nomor = exhibitionList[0].length;
      id = exhibitionList[0][nomor - 1];
      this.setState({
        transformationId: id.id,
      });
    } else {
      id = exhibitionList[0][index - 1];
      this.setState({
        transformationId: id.id,
      });
    }
  };

  changeExhibitionHallRight = () => {
    const { transformationId } = this.state;
    const { exhibitionList } = this.props;

    let index = exhibitionList[0].findIndex((x) => x.id === transformationId);
    let id;
    if (exhibitionList[0].length - 1 === index) {
      id = exhibitionList[0][0];
      this.setState({
        transformationId: id.id,
      });
    } else {
      id = exhibitionList[0][index + 1];
      this.setState({
        transformationId: id.id,
      });
    }
  };

  fetchDetailExhibitionByArrowLeft = () => {
    const { transformationId } = this.state;
    const { exhibitionList } = this.props;

    let index = exhibitionList[0].findIndex((x) => x.id === transformationId);
    let id;
    if (index === 0) {
      let nomor = exhibitionList[0].length;
      id = exhibitionList[0][nomor - 1];
    } else {
      id = exhibitionList[0][index - 1];
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DETAIL_EXHIBITION}${id.id}`
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            loading: false,
            stage: "ExhibitionDetail",
            detailExhibition: response.data.data.detail,
            detailExhibitionChildren: response.data.data.children,
            transformationId: id.id,
            transformationLogo: id.logo,
          });
        } else {
          this.setState({
            loading: false,
            detailExhibition: [],
            detailExhibitionChildren: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          detailExhibition: [],
          detailExhibitionChildren: [],
        });
      });
  };

  clickActiveBoothArticle = (n, index) => {
    this.setState({ linkShowing: n, activeMenu: index });
  };

  fetchDetailExhibitionByArrowRight = () => {
    const { transformationId } = this.state;
    const { exhibitionList } = this.props;

    let index = exhibitionList[0].findIndex((x) => x.id === transformationId);
    let id;
    if (exhibitionList[0].length - 1 === index) {
      id = exhibitionList[0][0];
    } else {
      id = exhibitionList[0][index + 1];
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DETAIL_EXHIBITION}${id.id}`
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            loading: false,
            stage: "ExhibitionDetail",
            detailExhibition: response.data.data.detail,
            detailExhibitionChildren: response.data.data.children,
            transformationId: id.id,
            transformationLogo: id.logo,
          });
        } else {
          this.setState({
            loading: false,
            detailExhibition: [],
            detailExhibitionChildren: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          detailExhibition: [],
          detailExhibitionChildren: [],
        });
      });
  };

  fetchDetailBooth = (id, name) => {
    this.setState({
      loading: true,
      titleModal: name,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DETAIL_BOOTH_BY_ID}${id}`
      )
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          this.setState({
            loading: false,
            detailBooth: response.data.data,
            stage: "ModalBooth",
            linkShowing: response.data.data[0],
          });
        } else {
          this.setState({
            loading: false,
            detailBooth: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          detailExhibition: [],
          detailExhibitionChildren: [],
        });
      });
  };

  showTransformationModal = (data) => {
    if (data.id !== undefined) {
      this.setState({
        transformationId: data.id,
        transformationModal: true,
        transformationLabel: data.name,
        transformationLabelTwo: data.description,
        transformationLogo: data.logo,
      });
    } else {
      this.setState({
        masukkanModal: true,
      });
    }
  };

  homeReload() {
    window.location.reload();
  }

  registrationModal = () => {
    const { registrationModal, start_song_on_first_click } = this.state;
    if (start_song_on_first_click) {
      this.playSound();
      this.setState({
        registrationModal: !registrationModal,
        start_song_on_first_click: false,
      });
    } else {
      this.setState({
        registrationModal: !registrationModal,
        start_song_on_first_click: false,
      });
    }
  };

  contactUsModal = () => {
    const { contactUsModal } = this.state;
    this.setState({
      contactUsModal: !contactUsModal,
      footerModal: false,
    });
  };

  playlistModal = () => {
    const { playlistModal } = this.state;
    this.setState({
      playlistModal: !playlistModal,
      footerModal: false,
    });
  };

  goToExhibitionHallModal = () => {
    const { user_id } = this.state;
    if (user_id === null) {
      alert("Mohon mengisi buku tamu terlebih dahulu");
    } else {
      this.setState({
        confirmationGoToExhibitionHall:
          !this.state.confirmationGoToExhibitionHall,
      });
    }
  };

  goToZoominarModal = () => {
    const { user_id } = this.state;
    if (user_id === null) {
      alert("Mohon mengisi buku tamu terlebih dahulu");
    } else {
      this.setState({
        confirmationGoToZoominar: !this.state.confirmationGoToZoominar,
      });
      this.props.fetchHomepage();
    }
  };

  goToZoominar = async () => {
    await this.setState({
      confirmationGoToZoominar: !this.state.confirmationGoToZoominar,
    });
    await setTimeout(() => {
      this.pauseSound();
      this.userVisitZoominar();
    }, 500);
  };

  closeModalBooth = () => {
    this.setState({
      stage: "ExhibitionDetail",
      activeMenu: 0,
    });
  };

  goToExhibitionHall = async () => {
    await this.setState({
      confirmationGoToExhibitionHall:
        !this.state.confirmationGoToExhibitionHall,
    });
    await setTimeout(() => {
      this.playSound();
      this.userVisitExhibition();
    }, 500);
  };

  getYoutubeEmbedCode = (url) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    let code = match && match[7].length == 11 ? match[7] : false;
    return `https://www.youtube.com/embed/${code}`;
  };

  getYoutubeEmbedImg = (url) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    let code = match && match[7].length == 11 ? match[7] : false;
    return `http://img.youtube.com/vi/${code}/0.jpg`;
  };

  fetchVideoList = () => {
    this.setState({
      loading: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/user/zoominar`)
      .then((response) => {
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          let videoList = response.data.data.list_video;
          this.setState({
            loading: false,
            videoList: videoList,
          });
        } else {
          this.setState({
            loading: false,
            videoList: [],
          });
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          videoList: [],
        });
      });
  };
}

const MapStateToProps = (state) => {
  return {
    homepage: state.homepage.homepage.data,
    zoominar: state.zoominar.zoominar.data,
    registrationAttribute:
      state.registrationAttribute.registrationAttribute.data,
    contactUsAttribute: state.contactUsAttribute.contactUsAttribute.data,
    masukkanAttribute: state.masukkanAttribute.masukkanAttribute.data,
    registration: state.registrationReducer,
    exhibitionList: state.exhibitionList.exhibitionList.data,
    askBooth: state.askBooth.askBoothAttribute.data,
  };
};

const MapDispatchToProps = (dispatch) => {
  return {
    fetchHomepage: () => dispatch(fetchHomepage()),
    fetchZoominar: () => dispatch(fetchZoominar()),
    fetchRegistrationAttribute: () => dispatch(fetchRegistrationAttribute()),
    fetchContactUsAttribute: () => dispatch(fetchContactUsAttribute()),
    fetchExhibitionList: () => dispatch(fetchExhibitionList()),
    fetchMasukkanAttribute: () => dispatch(fetchMasukkanAttribute()),
    fetchAskBoothAttribute: () => dispatch(fetchAskBoothAttribute()),
    fetchRegistration: (params) => dispatch(fetchRegistration(params)),
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(Homepage);

import {
  FETCH_MASUKKAN_ATTRIBTUTE_REQUEST,
  FETCH_MASUKKAN_ATTRIBTUTE_SUCCESS,
  FETCH_MASUKKAN_ATTRIBTUTE_FAILURE,
} from "./masukkanAttributeTypes";

const initialState = {
  loading: false,
  masukkanAttribute: [],
  error: "",
};

const masukkanAttributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASUKKAN_ATTRIBTUTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MASUKKAN_ATTRIBTUTE_SUCCESS:
      return {
        loading: false,
        masukkanAttribute: action.payload,
        error: "",
      };
    case FETCH_MASUKKAN_ATTRIBTUTE_FAILURE:
      return {
        loading: false,
        contactUsAttribute: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default masukkanAttributeReducer;

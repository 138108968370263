import React from "react";

const ExhibitionDetailWebsite = ({ children, stageBack }) => {
  return (
    <div>
      <div className="breadcrumbs-exhibition-detail">
        <span className="with-link">Beranda</span>
        <span className="slash"> / </span>
        <span className="with-link">Exhibition Hall</span>
        <span className="slash"> / </span>
        <span className="non-link">Booth details</span>
      </div>

      <div className="navigation-left-exhibition-detail" onClick={stageBack}>
        <span className="with-link">{"<"} Kembali</span>
      </div>
      {children}
    </div>
  );
};

export default ExhibitionDetailWebsite;

import React from "react";
import { HamburgerIcon } from "../../atoms";
import { useSpring, animated } from "react-spring";
import "./style.scss";

const TopMenu = ({ device, logoCompany, eventButton }) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  return (
    <animated.div
      className={`${
        device === "OTHERS" ? "top-menu-browser" : "top-menu-mobile"
      }`}
      style={props}
    >
      {device === "OTHERS" ? (
        <img
          className={`${
            device === "OTHERS"
              ? "top-menu-logo-browser"
              : "top-menu-logo-mobile"
          }`}
          src={logoCompany}
          alt="logo-pan-rb"
        ></img>
      ) : (
        <div className="flexing">
          <div className="top-menu-mobile-hamburger">
            <HamburgerIcon eventButton={eventButton} />
          </div>
          <div className="top-menu-mobile-logo">
            {" "}
            <img
              className={`${
                device === "OTHERS"
                  ? "top-menu-logo-browser"
                  : "top-menu-logo-mobile"
              }`}
              src={logoCompany}
              alt="logo-pan-rb"
            ></img>
          </div>
        </div>
      )}
    </animated.div>
  );
};

export default TopMenu;

import React from "react";
import { SecondaryButton } from "..";
import "./style.scss";
const IsiBukuTamuMobile = ({ fillFormRegister, user_id }) => {
  return (
    <div>
      {user_id === null && (
        <>
          <div className="container-center">
            <div
              style={{
                background: "#fff",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="bukutamu-ucapan">
                Selamat datang di Culture Fest ASN 2021, <br /> Silahkan untuk
                mengisi buku tamu digital.
              </div>
              <SecondaryButton
                className="bukutamu"
                label={"Isi Buku Tamu"}
                onClick={fillFormRegister}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IsiBukuTamuMobile;

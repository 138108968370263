import React, { Component } from "react";
import { SecondaryButton, ActionButton, IsiBukuTamu } from "../../atoms";
import { FooterWebsite } from "../../molecules";
import "./style.scss";
import AnimationRepsetionistMale from "../../../assets/img/animation-repsesionis-male.gif";
import AnimationRepsetionistFemale from "../../../assets/img/animation-repsesionis-female.gif";
import AnimationGroupOne from "../../../assets/img/animation-group-one.gif";
import AnimationGroupTwo from "../../../assets/img/animation-group-two.gif";
import AnimationGroupThree from "../../../assets/img/animation-group-three.gif";
import AnimationGroupFour from "../../../assets/img/animation-group-four.gif";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class HomepageWebsite extends Component {
  render() {
    const {
      homepage,
      goToExhibitionHall,
      user_id,
      fillFormRegister,
      contactUsModal,
      footerModal,
      onChangeFooterModal,
      goToZoominar,
      onChangeSound,
      sound,
      template_config,
    } = this.props;
    return (
      <div>
        <Carousel
          className="banner-kanan"
          showThumbs={false}
          autoPlay={true}
          interval={2000}
          infiniteLoop={true}
        >
          <div>
            <img src={homepage ? homepage[1][0].url : ""} alt="pan-rb" />
          </div>
          <div>
            <img src={homepage ? homepage[1][1].url : ""} alt="pan-rb" />
          </div>
          <div>
            <img src={homepage ? homepage[1][2].url : ""} alt="pan-rb" />
          </div>
        </Carousel>

        <img
          src={homepage ? homepage[1][0].url : ""}
          alt="banner"
          className="banner-kiri"
        />

        {/* <img
          src={homepage ? homepage[1][1].url : ""}
          alt="banner"
          className="banner-kanan"
        /> */}

        <IsiBukuTamu user_id={user_id} fillFormRegister={fillFormRegister} />

        <SecondaryButton
          className="secondary-button-left"
          label={"Zoominar"}
          onClick={goToZoominar}
        />

        <SecondaryButton
          className="secondary-button-right"
          label={"Exhibition Hall"}
          onClick={goToExhibitionHall}
        />

        <img
          className="AnimationRepsetionistMale"
          src={AnimationRepsetionistMale}
          alt="animation"
        />
        <img
          className="AnimationRepsetionistFemale"
          src={AnimationRepsetionistFemale}
          alt="animation"
        />
        <img
          className="AnimationGroupOne"
          src={AnimationGroupOne}
          alt="animation"
        />

        <img
          className="AnimationGroupTwo"
          src={AnimationGroupTwo}
          alt="animation"
        />
        <img
          className="AnimationGroupThree"
          src={AnimationGroupThree}
          alt="animation"
        />
        <img
          className="AnimationGroupFour"
          src={AnimationGroupFour}
          alt="animation"
        />
        <img
          className="AnimationGroupFive"
          src={AnimationGroupFour}
          alt="animation"
        />

        <img
          className="AnimationGroupSix"
          src={AnimationGroupThree}
          alt="animation"
        />

        <FooterWebsite
          value={homepage ? homepage[0].total_visit : 0}
          onChangeContactUs={contactUsModal}
          footerModal={footerModal}
          onChangeFooterModal={onChangeFooterModal}
          onChangeSound={onChangeSound}
          sound={sound}
          template_config={template_config}
        />

        <ActionButton onChangeFooterModal={onChangeFooterModal} />
      </div>
    );
  }
}

export default HomepageWebsite;

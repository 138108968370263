import React, { Component } from "react";
import BackgroundSound from "../../../assets/mp3/Bank-Mandiri-2018.mp3";

class ConfirmationSong extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: new Audio(BackgroundSound),
    };
  }

  confirm = () => {
    this.state.audio.play();
    localStorage.setItem("play", false);
  };

  render() {
    const local = localStorage.getItem("play");
    console.log("local :", local);
    return (
      <>
        {local === true && (
          <div className="playsound">
            <div>Allow sites to play sound ?</div>
            <button onClick={() => this.confirm()}>Yes</button>
          </div>
        )}
      </>
    );
  }
}

export default ConfirmationSong;

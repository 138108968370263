import axios from "axios";

import {
  FETCH_HOMEPAGE_REQUEST,
  FETCH_HOMEPAGE_SUCCESS,
  FETCH_HOMEPAGE_FAILURE,
} from "./homepageTypes";

export const fetchHomepageRequest = () => {
  return {
    type: FETCH_HOMEPAGE_REQUEST,
  };
};

export const fetchHomepageSuccess = (data) => {
  return {
    type: FETCH_HOMEPAGE_SUCCESS,
    payload: data,
  };
};

export const fetchHomepageFailure = (error) => {
  return {
    type: FETCH_HOMEPAGE_FAILURE,
    payload: error,
  };
};

export const fetchHomepage = () => {
  return (dispatch) => {
    dispatch(fetchHomepageRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOMEPAGE_USER}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const homepage = response.data;

        dispatch(fetchHomepageSuccess(homepage));
      })
      .catch((errors) => {
        dispatch(fetchHomepageFailure(errors));
      });
  };
};

import React from "react";
import { withFormsy } from "formsy-react";
import "./style.scss";
class InputNip extends React.Component {
  render() {
    const { label, errorMessage } = this.props;
    return (
      <div className="form-control">
        <div className="form-label">{label}</div>
        <div>
          <input
            style={{ height: "37.07px" }}
            type="number"
            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            value={this.props.value}
            onChange={(e) => this.props.setValue(e.target.value)}
          />
          <div className="form-error">{errorMessage}</div>
          {(this.props.value?.length < 1 ||
            this.props.value?.length === undefined) && (
            <div className="form-error">{`${label} harap diisi`}</div>
          )}
        </div>
      </div>
    );
  }
}
export default withFormsy(InputNip);

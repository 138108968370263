import React from "react";
import { useSpring, animated } from "react-spring";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "../../atoms";

const styles = (props) => ({
  colorPrimary: {
    backgroundColor: "#B42B2D",
  },
  barColorPrimary: {
    backgroundColor: "#ffdedb",
  },
});

const ModalConfirmation = ({
  show,
  label,
  msg,
  event,
  labelButton,
  icon,
  width,
  closeModal,
  checkCloseModal,
}) => {
  const props = useSpring({
    top: show ? 10 : -2000,
    position: "absolute",
    width: "100%",
    zIndex: 999,
    height: "95vh",
  });
  return (
    <animated.div style={props} onClick={closeModal}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          borderRadius: "4px",
          padding: "10px 10px 20px 10px",
          margin: "auto",
          width: width,
          background: "#fff",
          textAlign: "center",
          boxShadow: "0px 0px 30px 10px rgba(0, 0, 0, 0.4)",
        }}
      >
        {checkCloseModal && (
          <div className="form-control-close" onClick={closeModal}>
            <div className="form-label-close">x</div>
          </div>
        )}

        <img src={icon} alt="success" width="100" style={{ marginTop: 30 }} />
        <div className="form-control-title">
          <div className="form-label-title">{label}</div>
        </div>
        <div className="form-control-msg" style={{ marginBottom: 20 }}>
          <div className="form-label-msg">{msg}</div>
        </div>
        <div style={{ marginBottom: 30 }}>
          <Button event={event} label={labelButton} className="sm-button" />
        </div>
      </div>
    </animated.div>
  );
};

export default withStyles(styles)(ModalConfirmation);

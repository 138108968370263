import axios from "axios";

import {
  FETCH_ZOOMINAR_REQUEST,
  FETCH_ZOOMINAR_SUCCESS,
  FETCH_ZOOMINAR_FAILURE,
} from "./zoominarTypes";

export const fetchZoominarRequest = () => {
  return {
    type: FETCH_ZOOMINAR_REQUEST,
  };
};

export const fetchZoominarSuccess = (data) => {
  return {
    type: FETCH_ZOOMINAR_SUCCESS,
    payload: data,
  };
};

export const fetchZoominarFailure = (error) => {
  return {
    type: FETCH_ZOOMINAR_FAILURE,
    payload: error,
  };
};

export const fetchZoominar = () => {
  return (dispatch) => {
    dispatch(fetchZoominarRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ZOOMINAR}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const zoominar = response.data;

        dispatch(fetchZoominarSuccess(zoominar));
      })
      .catch((errors) => {
        dispatch(fetchZoominarFailure(errors));
      });
  };
};

import React from "react";
import { useSpring, animated } from "react-spring";
import SoundOn from "../../../assets/img/sound-on.png";
import SoundOff from "../../../assets/img/sound-off.png";

const FooterMobile = ({
  value,
  sound,
  onChangeSound,
  onChangeContactUs,
  footerModal,
  onChangeFooterModal,
}) => {
  const props = useSpring({
    display: footerModal ? "block" : "none",
    cursor: "pointer",
  });
  return (
    <animated.div
      style={props}
      className="all-screen"
      onClick={onChangeFooterModal}
    >
      <div style={props} className="container-footer-mobile move-top">
        <div className="item">
          <span className="visitor">{value}</span>{" "}
          <span className="pengunjung">Pengunjung</span>
        </div>
        <div className="item">
          <span className="sound-container" onClick={onChangeSound}>
            <img
              className="sound"
              src={sound ? SoundOn : SoundOff}
              alt="logo-pan-rb"
            ></img>
            {sound ? "On" : "Off"}
          </span>
        </div>
        <div className="item" onClick={onChangeContactUs}>
          <span className="kontak-kami">Tanya Kami?</span>
        </div>
      </div>
    </animated.div>
  );
};

export default FooterMobile;

import {
  FETCH_ZOOMINAR_REQUEST,
  FETCH_ZOOMINAR_SUCCESS,
  FETCH_ZOOMINAR_FAILURE,
} from "./zoominarTypes";

const initialState = {
  loading: false,
  zoominar: [],
  error: "",
  music: false,
};

const zoominarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ZOOMINAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ZOOMINAR_SUCCESS:
      return {
        loading: false,
        zoominar: action.payload,
        error: "",
      };
    case FETCH_ZOOMINAR_FAILURE:
      return {
        loading: false,
        zoominar: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default zoominarReducer;

import axios from "axios";

import {
  FETCH_REGISTRATION_ATTRIBTUTE_REQUEST,
  FETCH_REGISTRATION_ATTRIBTUTE_SUCCESS,
  FETCH_REGISTRATION_ATTRIBTUTE_FAILURE,
} from "./registrationAttributeTypes";

export const fetchRegistrationAttributeRequest = () => {
  return {
    type: FETCH_REGISTRATION_ATTRIBTUTE_REQUEST,
  };
};

export const fetchRegistrationAttributeSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_ATTRIBTUTE_SUCCESS,
    payload: data,
  };
};

export const fetchRegistrationAttributeFailure = (error) => {
  return {
    type: FETCH_REGISTRATION_ATTRIBTUTE_FAILURE,
    payload: error,
  };
};

export const fetchRegistrationAttribute = () => {
  return (dispatch) => {
    dispatch(fetchRegistrationAttributeRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REGISTRATION_ATTRIBUTE}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const registration = response.data;

        dispatch(fetchRegistrationAttributeSuccess(registration));
      })
      .catch((errors) => {
        dispatch(fetchRegistrationAttributeFailure(errors));
      });
  };
};

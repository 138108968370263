import React from "react";
import { useSpring, animated } from "react-spring";
import { ActionButtonTop } from "../../atoms";
import { FooterMobileCustom } from "../../molecules";
import "./style.scss";

const ZoominarMobile = ({
  homepage,
  contactUsModal,
  stageBeranda,
  stageZoominar,
  children,
  footerModal,
  onChangeFooterModal,
  toZoominar,
  zoominar,
  toHome,
  stage,
  template_config,
  onChangeSound,
  sound,
  onClickDaftarVideo,
}) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div props={props}>
      <div onClick={toHome} className="zm-button-beranda">
        {" < "}Beranda
      </div>
      <div onClick={toZoominar} className="zm-button-eh">
        Exhibition Hall {" > "}
      </div>

      {children}

      <button
        onClick={onClickDaftarVideo}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          width: "40%",
          border: "1px solid #b42b2d",
          padding: 10,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          background: "#ffffff",
          borderRadius: 20,
          color: "#b42b2d",
        }}
      >
        Daftar Video
      </button>

      <ActionButtonTop
        onChangeFooterModal={onChangeFooterModal}
        style={{ bottom: "40px" }}
      />

      <div class="marquee">
        {/* <p>
          {zoominar.list_news_and_image
            ? zoominar.list_news_and_image.map((n, index) => {
                return (
                  <span>
                    <a href={n.url} target="_blank" rel="noreferrer">
                      {n.name}
                    </a>
                  </span>
                );
              })
            : ""}
        </p> */}
        <marquee behavior="scroll" direction="left" scrollamount="12">
          <span>Selamat datang para pengunjung ASN Culture Festival 2021</span>
          <span>
            Anda dapat juga melihat berbagai kegiatan/dokumen terkait Budaya
            Kerja ASN BerAKHLAK dan Employer Branding Bangga Melayani Bangsa di
            beberapa Instansi Pemerintah pada Exhibition Hall
          </span>
          <span>
            Instansi Pemerintah diharapkan telah menyelesaikan survei core
            values ASN paling lambat tanggal 25 Desember 2021 sesuai dengan
            Surat Kementerian PANRB Nomor : B/235/S.SM.00.00/2021
          </span>
          <span>
            Instansi Pemerintah agar mengirimkan tautan laporan hasil
            implementasi BerAKHLAK dan Employer Branding Bangga Melayani Bangsa
            melalui email: asnberakhlak@menpan.go.id paling lambat 31 Desember
            2021
          </span>
        </marquee>
      </div>

      <FooterMobileCustom
        value={homepage ? homepage[0].total_visit : 0}
        onChangeContactUs={contactUsModal}
        footerModal={footerModal}
        onChangeFooterModal={onChangeFooterModal}
        template_config={template_config}
        onChangeSound={onChangeSound}
        sound={sound}
      />
    </animated.div>
  );
};

export default ZoominarMobile;

import React from "react";
import { useSpring, animated } from "react-spring";

const ContainerShowFromTop = ({
  show,
  children,
  width,
  closeWhenOutScreen,
}) => {
  const props = useSpring({
    top: show ? 0 : -2000,
    position: "absolute",
    width: "100%",
    zIndex: 999,
    display: "flex",
  });
  return (
    <animated.div style={props}>
      <div
        style={{
          borderRadius: "4px",
          padding: "5px 10px 30px 10px",
          zIndex: 99999,
          height: "92vh",
          width: "95vw",
          position: "absolute",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onClick={closeWhenOutScreen}
      ></div>
      <div
        style={{
          borderRadius: "4px",
          padding: "5px 10px 30px 10px",
          margin: "auto",
          width: width,
          background: "white",
          zIndex: 99999,
          marginTop: 80,
        }}
      >
        {children}
      </div>
    </animated.div>
  );
};

export default ContainerShowFromTop;

import React, { Component } from "react";
import { ContainerShowFromTop } from "../../atoms";

class ModalFormCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { show, closeModal, children, title, width, closeWhenOutScreen } = this.props;
    return (
      <>
        <ContainerShowFromTop closeWhenOutScreen={closeWhenOutScreen} show={show} width={width}>
          <div className="form-control-close floating" onClick={closeModal}>
            <div className="form-label-close">x</div>
          </div>
          <div className="form-control-title">
            <div className="form-label-title">{title}</div>
          </div>
          {children}
        </ContainerShowFromTop>
      </>
    );
  }
}

export default ModalFormCustom;

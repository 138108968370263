import axios from "axios";

import {
  FETCH_ASKBOOTH_ATTRIBTUTE_REQUEST,
  FETCH_ASKBOOTH_ATTRIBTUTE_SUCCESS,
  FETCH_ASKBOOTH_ATTRIBTUTE_FAILURE,
} from "./askBoothAttributeTypes";

export const fetchAskBoothAttributeRequest = () => {
  return {
    type: FETCH_ASKBOOTH_ATTRIBTUTE_REQUEST,
  };
};

export const fetchAskBoothAttributeSuccess = (data) => {
  return {
    type: FETCH_ASKBOOTH_ATTRIBTUTE_SUCCESS,
    payload: data,
  };
};

export const fetchAskBoothAttributeFailure = (error) => {
  return {
    type: FETCH_ASKBOOTH_ATTRIBTUTE_FAILURE,
    payload: error,
  };
};

export const fetchAskBoothAttribute = () => {
  return (dispatch) => {
    dispatch(fetchAskBoothAttributeRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ASKBOOTH_ATTRIBUTE}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const askBoothAttribute = response.data;

        dispatch(fetchAskBoothAttributeSuccess(askBoothAttribute));
      })
      .catch((errors) => {
        dispatch(fetchAskBoothAttributeFailure(errors));
      });
  };
};

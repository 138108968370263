/* eslint-disable import/no-anonymous-default-export */
export const getQueryVariable = async (variable) => {
  const data = new URLSearchParams(window.location.search).get(variable);
  return data;
};

export const getMobileOperatingSystem = () => {
  if (!process.browser) return "OTHERS";
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) return "ANDROID";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "IOS";
  return "OTHERS";
};

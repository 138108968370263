import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import homepageReducer from "./homepage/homepageReducer";
import registrationAttributeReducer from "./registrationAttribute/registrationAttributeReducer";
import contactUsAttributeReducer from "./contactUsAttribute/contactUsAttributeReducer";
import registrationReducer from "./registration/registrationReducer";
import exhibitionListReducer from "./exhibitionList/exhibitionListReducer";
import masukkanAttributeReducer from "./masukkanAttribute/masukkanAttributeReducer";
import zoominarReducer from "./zoominar/zoominarReducer";
import askBoothReducer from "./askBooth/askBoothAttributeReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  homepage: homepageReducer,
  registrationAttribute: registrationAttributeReducer,
  contactUsAttribute: contactUsAttributeReducer,
  registrationReducer: registrationReducer,
  exhibitionList: exhibitionListReducer,
  masukkanAttribute: masukkanAttributeReducer,
  zoominar: zoominarReducer,
  askBooth: askBoothReducer,
});

export default rootReducer;

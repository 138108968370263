import axios from "axios";

import {
  FETCH_CONTACT_US_ATTRIBTUTE_REQUEST,
  FETCH_CONTACT_US_ATTRIBTUTE_SUCCESS,
  FETCH_CONTACT_US_ATTRIBTUTE_FAILURE,
} from "./contactUsAttributeTypes";

export const fetchContactUsAttributeRequest = () => {
  return {
    type: FETCH_CONTACT_US_ATTRIBTUTE_REQUEST,
  };
};

export const fetchContactUsAttributeSuccess = (data) => {
  return {
    type: FETCH_CONTACT_US_ATTRIBTUTE_SUCCESS,
    payload: data,
  };
};

export const fetchContactUsAttributeFailure = (error) => {
  return {
    type: FETCH_CONTACT_US_ATTRIBTUTE_FAILURE,
    payload: error,
  };
};

export const fetchContactUsAttribute = () => {
  return (dispatch) => {
    dispatch(fetchContactUsAttributeRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTACT_US_ATTRIBUTE}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const contactUs = response.data;

        dispatch(fetchContactUsAttributeSuccess(contactUs));
      })
      .catch((errors) => {
        dispatch(fetchContactUsAttributeFailure(errors));
      });
  };
};

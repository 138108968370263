import React from "react";
import { withFormsy } from "formsy-react";
import "./style.scss";
class InputTextArea extends React.Component {
  render() {
    const { label, errorMessage } = this.props;
    return (
      <div className="form-control">
        <div className="form-label">{label}</div>
        <div>
          <textarea
            rows="4"
            style={{ width: "98%", marginTop: 4 }}
            onChange={(e) => this.props.setValue(e.target.value)}
          >
            {this.props.value}
          </textarea>
          <div className="form-error">{errorMessage}</div>
        </div>
      </div>
    );
  }
}
export default withFormsy(InputTextArea);

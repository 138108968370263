import React from "react";

import "./style.scss";
const ModalTwoLayout = ({ title, closeModal, children }) => {
  return (
    <div
      style={{
        borderRadius: "4px",
        background: "#fff",
        textAlign: "center",
        boxShadow: "0px 0px 30px 10px rgba(0, 0, 0, 0.4)",
        height: "100vh",
      }}
    >
      <div className="title-with-close-modal" onClick={closeModal}>
        <div className="title">{title}</div>
        <div className="close">x</div>
      </div>
      <div className="modal-two-layout-body">{children}</div>
    </div>
  );
};

export default ModalTwoLayout;

import React, { Component } from "react";
import AnimationRepsetionistMale from "../../../assets/img/animation-repsesionis-male.gif";
import AnimationRepsetionistFemale from "../../../assets/img/animation-repsesionis-female.gif";
import { ActionButton, IsiBukuTamuMobile } from "../../atoms";
import "./style.scss";
import { FooterMobile } from "../../molecules";
class HomepageMobile extends Component {
  render() {
    const {
      user_id,
      fillFormRegister,
      toZoominar,
      toExhibitionHall,
      onChangeFooterModal,
      contactUsModal,
      homepage,
      footerModal,
      onChangeSound,
      sound,
      template_config,
    } = this.props;
    return (
      <div>
        <IsiBukuTamuMobile
          user_id={user_id}
          fillFormRegister={fillFormRegister}
        />
        <img
          className="AnimationRepsetionistMaleMobile"
          src={AnimationRepsetionistMale}
          alt="animation"
        />
        <img
          className="AnimationRepsetionistFemaleMobile"
          src={AnimationRepsetionistFemale}
          alt="animation"
        />

        <div className="button-group-homepage-mobile">
          <div onClick={toZoominar} className="button-homepage-mobile">
            Zoominar
          </div>
          <div onClick={toExhibitionHall} className="button-homepage-mobile">
            Exhibition Hall
          </div>
        </div>

        <ActionButton onChangeFooterModal={onChangeFooterModal} />

        <FooterMobile
          value={homepage ? homepage[0].total_visit : 0}
          onChangeContactUs={contactUsModal}
          footerModal={footerModal}
          onChangeFooterModal={onChangeFooterModal}
          onChangeSound={onChangeSound}
          sound={sound}
          template_config={template_config}
        />
      </div>
    );
  }
}

export default HomepageMobile;

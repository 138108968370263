import axios from "axios";

import {
  FETCH_REGISTRATION_REQUEST,
  FETCH_REGISTRATION_SUCCESS,
  FETCH_REGISTRATION_FAILURE,
} from "./registrationTypes";

export const fetchRegistrationRequest = () => {
  return {
    type: FETCH_REGISTRATION_REQUEST,
  };
};

export const fetchRegistrationSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_SUCCESS,
    payload: data,
  };
};

export const fetchRegistrationFailure = (error) => {
  return {
    type: FETCH_REGISTRATION_FAILURE,
    payload: error,
  };
};

export const fetchRegistration = (data) => {
  return (dispatch) => {
    dispatch(fetchRegistrationRequest);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REGISTRATION}`,
      data,
      validateStatus: () => true,
    })
      .then((response) => {
        const registration = response;
        dispatch(fetchRegistrationSuccess(registration));
      })
      .catch((errors) => {
        dispatch(fetchRegistrationFailure(errors));
      });
  };
};

import React from "react";
import { useSpring, animated } from "react-spring";
import { ActionButton } from "../../atoms";
import { FooterWebsite } from "../../molecules";
import "./style.scss";
import Flower from "../../../assets/img/flower.png";
import AnimationGroupOne from "../../../assets/img/animation-group-one.gif";
import AnimationGroupTwo from "../../../assets/img/animation-group-two.gif";
import AnimationGroupThree from "../../../assets/img/animation-group-three.gif";
import AnimationGroupFour from "../../../assets/img/animation-group-four.gif";

const ExhibitionWebsite = ({
  homepage,
  contactUsModal,
  stageBeranda,
  stageZoominar,
  children,
  footerModal,
  onChangeFooterModal,
  stage,
  onChangeSound,
  sound,
  template_config,
}) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div props={props}>
      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <span className="with-link">Beranda</span>
        <span className="slash"> / </span>
        <span className="non-link">Exhibition Hall</span>
      </div>

      <div className="navigation-left" onClick={stageBeranda}>
        <span className="with-link">{"<"} Beranda</span>
      </div>

      <div className="navigation-right" onClick={stageZoominar}>
        <span className="with-link">Zoominar {">"}</span>
      </div>

      {children}

      <img className="Flower1" src={Flower} alt="flower1" />
      <img className="Flower2" src={Flower} alt="flower1" />
      <img className="Flower3" src={Flower} alt="flower1" />
      <img className="Flower4" src={Flower} alt="flower1" />
      <img className="Flower5" src={Flower} alt="flower1" />
      <img className="Flower6" src={Flower} alt="flower1" />

      <img
        className="ExhibitionAnimationGroupOne"
        src={AnimationGroupOne}
        alt="animation"
      />
      <img
        className="ExhibitionAnimationGroupTwo"
        src={AnimationGroupTwo}
        alt="animation"
        style={{ opacity: stage === "Exhibition" ? 1 : 0 }}
      />
      <img
        className="ExhibitionAnimationGroupThree"
        src={AnimationGroupThree}
        alt="animation"
        style={{ opacity: stage === "Exhibition" ? 1 : 0 }}
      />
      <img
        className="ExhibitionAnimationGroupFour"
        src={AnimationGroupFour}
        alt="animation"
        style={{ opacity: stage === "Exhibition" ? 1 : 0 }}
      />
      <img
        className="ExhibitionAnimationGroupFive"
        src={AnimationGroupFour}
        alt="animation"
        style={{ opacity: stage === "Exhibition" ? 1 : 0 }}
      />

      <FooterWebsite
        value={homepage ? homepage[0].total_visit : 0}
        onChangeContactUs={contactUsModal}
        footerModal={footerModal}
        onChangeFooterModal={onChangeFooterModal}
        onChangeSound={onChangeSound}
        sound={sound}
        template_config={template_config}
      />

      <ActionButton onChangeFooterModal={onChangeFooterModal} />
    </animated.div>
  );
};

export default ExhibitionWebsite;

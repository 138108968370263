import React from "react";
import { withFormsy } from "formsy-react";
import "./style.scss";
class InputEmail extends React.Component {
  render() {
    const { label, errorMessage } = this.props;

    return (
      <div className="form-control">
        <div className="form-label">{label}</div>
        <div>
          <input
            style={{ height: "37.07px" }}
            type="text"
            value={this.props.value}
            onChange={(e) => this.props.setValue(e.target.value)}
          />
          <div className="form-error">{errorMessage}</div>
        </div>
      </div>
    );
  }
}
export default withFormsy(InputEmail);

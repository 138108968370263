import React from "react";
import "./style.scss";
import { useSpring, animated } from "react-spring";
import SoundOn from "../../../assets/img/sound-on.png";
import SoundOff from "../../../assets/img/sound-off.png";

const FooterWebsite = ({
  value,
  footerModal,
  onChangeContactUs,
  onChangeFooterModal,
  onChangeSound,
  sound,
  template_config,
}) => {
  const props = useSpring({
    display: footerModal ? "block" : "none",
    cursor: "pointer",
  });
  return (
    <animated.div
      style={props}
      className="all-screen"
      onClick={onChangeFooterModal}
    >
      <div className="container-footer-center">
        <div className="container-footer-website">
          <div className="item-left">
            <span className="visitor">{value}</span>{" "}
            <span className="pengunjung">Pengunjung</span>
          </div>
          <div className="item">
            {template_config === 1 && (
              <span className="sound-container" onClick={onChangeSound}>
                <img
                  className="sound"
                  src={sound ? SoundOn : SoundOff}
                  alt="logo-pan-rb"
                ></img>
                {sound ? "On" : "Off"}
              </span>
            )}
          </div>
          <div
            className="item-right"
            style={{ zIndex: 9999 }}
            onClick={onChangeContactUs}
          >
            <span className="kontak-kami">Tanya Kami?</span>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default FooterWebsite;

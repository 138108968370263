import {
  FETCH_EXHIBITION_LIST_REQUEST,
  FETCH_EXHIBITION_LIST_SUCCESS,
  FETCH_EXHIBITION_LIST_FAILURE,
} from "./exhibitionListTypes";

const initialState = {
  loading: false,
  exhibitionList: [],
  error: "",
};

const exhibitionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXHIBITION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXHIBITION_LIST_SUCCESS:
      return {
        loading: false,
        exhibitionList: action.payload,
        error: "",
      };
    case FETCH_EXHIBITION_LIST_FAILURE:
      return {
        loading: false,
        exhibitionList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default exhibitionListReducer;

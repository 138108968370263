import {
  FETCH_REGISTRATION_REQUEST,
  FETCH_REGISTRATION_SUCCESS,
  FETCH_REGISTRATION_FAILURE,
} from "./registrationTypes";

const initialState = {
  loading: false,
  registration: [],
  error: "",
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REGISTRATION_SUCCESS:
      return {
        loading: false,
        registration: action.payload,
        error: "",
      };
    case FETCH_REGISTRATION_FAILURE:
      return {
        loading: false,
        registration: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default registrationReducer;

import React from "react";
import { useSpring, animated } from "react-spring";
import "./style.css";

const Background = ({ children, stage, device }) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  return (
    <animated.div style={props}>
      {device === "OTHERS" ? (
        <div
          className={
            stage === "Homepage"
              ? "homepage"
              : stage === "Zoominar"
              ? "zoominar"
              : stage === "Exhibition"
              ? "exhibition"
              : stage === "ExhibitionDetail"
              ? "booth"
              : ""
          }
        >
          {children}
        </div>
      ) : (
        <div
          className={
            stage === "Homepage"
              ? "homepage-mobile"
              : stage === "Zoominar"
              ? "zoominar-mobile"
              : stage === "Exhibition"
              ? "exhibition-mobile"
              : stage === "ExhibitionDetail"
              ? "booth-mobile"
              : ""
          }
        >
          {children}
        </div>
      )}
    </animated.div>
  );
};

export default Background;

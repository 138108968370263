import React from "react";

const Button = ({ event, label, className, disabled }) => {
  return (
    <div>
      <input
        onClick={event}
        type="submit"
        value={label}
        className={className}
        disabled={disabled}
        style={{ cursor: "pointer", zIndex: 999 }}
      />
    </div>
  );
};

export default Button;

import React from "react";
import { useSpring, animated } from "react-spring";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

const StyledModal = styled.div`
  background: red;
  border-radius: 4px;
  padding: 10px 10px 20px 10px;
  margin: auto;
  width: 80%;
  background: #fff;
  box-shadow: 1px 1px 400px 200px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 2px 5px 400px 200px rgba(0, 0, 0, 1);
  -moz-box-shadow: 2px 5px 400px 200px rgba(0, 0, 0, 1);
`;

const styles = (props) => ({
  colorPrimary: {
    backgroundColor: "#B42B2D",
  },
  barColorPrimary: {
    backgroundColor: "#ffdedb",
  },
});

const ModalLoading = ({ show, classes }) => {
  const props = useSpring({
    opacity: show ? 1 : 0,
    position: "absolute",
    width: "100%",
    top: "40%",
  });
  return (
    <animated.div style={props} className="modal-container">
      <StyledModal>
        <div className="form-control-title">
          <div className="form-label-title">Loading</div>
        </div>
        <LinearProgress
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </StyledModal>
    </animated.div>
  );
};

export default withStyles(styles)(ModalLoading);

import React from "react";
import { ActionButtonZoominar } from "../../atoms";
import { FooterWebsiteZoominar } from "../../molecules";
import "./style.scss";
const ZoominarWebsite = ({
  stageBeranda,
  stageExhibition,
  footerModal,
  onChangeFooterModal,
  contactUsModal,
  homepage,
  zoominar,
  children,
  onChangeSound,
  sound,
  template_config,
}) => {
  return (
    <div>
      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <span className="with-link">Beranda</span>
        <span className="slash"> / </span>
        <span className="non-link">Zoominar</span>
      </div>

      <div className="navigation-left" onClick={stageBeranda}>
        <span className="with-link">{"<"} Beranda</span>
      </div>

      <div className="navigation-right" onClick={stageExhibition}>
        <span className="with-link">Exhibition Hall {">"}</span>
      </div>

      {children}

      <FooterWebsiteZoominar
        value={homepage ? homepage[0].total_visit : 0}
        onChangeContactUs={contactUsModal}
        footerModal={footerModal}
        onChangeFooterModal={onChangeFooterModal}
        onChangeSound={onChangeSound}
        sound={sound}
        template_config={template_config}
      />

      <ActionButtonZoominar onChangeFooterModal={onChangeFooterModal} />

      <div class="marquee">
        {/* <p>
          {zoominar.list_news_and_image
            ? zoominar.list_news_and_image.map((n, index) => {
                return (
                  <span>
                    <a href={n.url} target="_blank" rel="noreferrer">
                      {n.name}
                    </a>
                  </span>
                );
              })
            : ""}
        </p> */}
        <marquee behavior="scroll" direction="left" scrollamount="12">
          <span>Selamat datang para pengunjung ASN Culture Festival 2021</span>
          <span>Anda dapat juga melihat berbagai kegiatan/dokumen terkait Budaya Kerja ASN BerAKHLAK dan Employer Branding Bangga Melayani Bangsa di beberapa Instansi Pemerintah pada Exhibition Hall</span>
          <span>Instansi Pemerintah diharapkan telah menyelesaikan survei core values ASN paling lambat tanggal 25 Desember 2021 sesuai dengan Surat Kementerian PANRB Nomor : B/235/S.SM.00.00/2021</span>
          <span>Instansi Pemerintah agar mengirimkan tautan laporan hasil implementasi BerAKHLAK dan Employer Branding Bangga Melayani Bangsa melalui email: asnberakhlak@menpan.go.id paling lambat 31 Desember 2021</span>
        </marquee>
      </div>
    </div>
  );
};

export default ZoominarWebsite;

import axios from "axios";

import {
  FETCH_EXHIBITION_LIST_REQUEST,
  FETCH_EXHIBITION_LIST_SUCCESS,
  FETCH_EXHIBITION_LIST_FAILURE,
} from "./exhibitionListTypes";

export const fetchExhibitionListRequest = () => {
  return {
    type: FETCH_EXHIBITION_LIST_REQUEST,
  };
};

export const fetchExhibitionListSuccess = (data) => {
  return {
    type: FETCH_EXHIBITION_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchExhibitionListFailure = (error) => {
  return {
    type: FETCH_EXHIBITION_LIST_FAILURE,
    payload: error,
  };
};

export const fetchExhibitionList = () => {
  return (dispatch) => {
    dispatch(fetchExhibitionListRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_EXHIBITION_LIST}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const exhibitionList = response.data;

        dispatch(fetchExhibitionListSuccess(exhibitionList));
      })
      .catch((errors) => {
        dispatch(fetchExhibitionListFailure(errors));
      });
  };
};

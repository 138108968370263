import React from "react";
import { useSpring, animated } from "react-spring";
import { ActionButton } from "../../atoms";
import { FooterMobile } from "../../molecules";
import "./style.scss";
import VideoLogo from "../../../assets/img/icon-video.png";
const ExhibitionDetailMobile = ({
  homepage,
  contactUsModal,
  children,
  footerModal,
  onChangeFooterModal,
  goBack,
  stage,
  date_exhibition,
  detailExhibition,
  detailExhibitionChildren,
  goToZoomExhibitionBoothByLink,
  openAskBooth,
  onChangeSound,
  sound,
  template_config,
}) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  return (
    <animated.div props={props}>
      <div onClick={goBack} className="button-exhibition-mobile-beranda">
        {" < "}Kembali
      </div>
      <div className="nama-instansi-exhibition-detail-mobile">
        {detailExhibition.name}
      </div>

      <div className="description-exhibition-detail-mobile">
        {detailExhibition.description}
      </div>

      {children}

      <div className="info-exhibition-detail-mobile">
        <div className="info">
          Booth akan live via zoom pada : <br /> {date_exhibition}
        </div>
        <div
          className="button-logo-and-text2"
          onClick={goToZoomExhibitionBoothByLink}
        >
          <div className="button-logo-and-text-icon-mobile-video">
            <img src={VideoLogo} alt="icon" />
          </div>
          <div className="bergabungdizoom">Bergabung di Zoom</div>
        </div>
        <div className="button-logo-and-text3" onClick={openAskBooth}>
          <div>Tanya Pengelola Booth ?</div>
        </div>
      </div>

      <ActionButton onChangeFooterModal={onChangeFooterModal} />

      <FooterMobile
        value={homepage ? homepage[0].total_visit : 0}
        onChangeContactUs={contactUsModal}
        footerModal={footerModal}
        onChangeFooterModal={onChangeFooterModal}
        onChangeSound={onChangeSound}
        sound={sound}
        template_config={template_config}
      />
    </animated.div>
  );
};

export default ExhibitionDetailMobile;

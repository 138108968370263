import React from "react";
import styled from "styled-components";

const StyledBurger = styled.button`
  position: absoluted;
  top: 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 0.25rem;
    background: #b42b2d;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

function HamburgerIcon({ eventButton }) {
  return (
    <StyledBurger onClick={eventButton}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}

export default HamburgerIcon;

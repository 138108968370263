import React from "react";
import { withFormsy } from "formsy-react";
import "./style.scss";
class InputPhone extends React.Component {
  render() {
    const { label, errorMessage } = this.props;
    return (
      <div className="form-control">
        <div className="form-label">{label}</div>
        <div className="container-input">
          <div className="kode-negara">+62</div>
          <div className="inputan">
            <input
              style={{ height: "37.07px" }}
              type="number"
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              value={this.props.value}
              onChange={(e) => this.props.setValue(e.target.value)}
            />
          </div>
        </div>
        <div className="form-error">{errorMessage}</div>
        {(this.props.value?.length < 1 ||
          this.props.value?.length === undefined) && (
          <div className="form-error">{`${label} harap diisi`}</div>
        )}
      </div>
    );
  }
}
export default withFormsy(InputPhone);

import React from "react";

const SecondaryButton = ({ onClick, label, className }) => {
  return (
    <button className={className} onClick={onClick}>
      {label}
    </button>
  );
};

export default SecondaryButton;

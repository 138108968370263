import React from "react";
import ActionButtonLogo from "./img/footer-logo.png";
import "./style.scss";
const ActionButtonZoominar = ({ onChangeFooterModal }) => {
  return (
    <div
      className="centering-action-button-zoominar"
      onClick={onChangeFooterModal}
    >
      <img class="action" src={ActionButtonLogo} alt="action button" />
    </div>
  );
};

export default ActionButtonZoominar;

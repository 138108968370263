import React from "react";
import { useSpring, animated } from "react-spring";
import { ActionButton } from "../../atoms";
import { FooterMobile } from "../../molecules";
import "./style.scss";

const ExhibitionMobile = ({
  homepage,
  contactUsModal,
  stageBeranda,
  stageZoominar,
  children,
  footerModal,
  onChangeFooterModal,
  toZoominar,
  toHome,
  onChangeSound,
  stage,
  sound,
  template_config,
}) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div props={props}>
      <div onClick={toHome} className="button-exhibition-mobile-beranda">
        {" < "}Beranda
      </div>
      <div onClick={toZoominar} className="button-exhibition-mobile-zoominar">
        Zoominar{" > "}
      </div>
      {children}

      <ActionButton onChangeFooterModal={onChangeFooterModal} />

      <FooterMobile
        value={homepage ? homepage[0].total_visit : 0}
        onChangeContactUs={contactUsModal}
        footerModal={footerModal}
        onChangeFooterModal={onChangeFooterModal}
        onChangeSound={onChangeSound}
        sound={sound}
        template_config={template_config}
      />
    </animated.div>
  );
};

export default ExhibitionMobile;

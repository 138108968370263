import React from "react";
import ActionButtonLogo from "./img/footer-logo.png";
import "./style.scss";
const ActionButton = ({ onChangeFooterModal }) => {
  return (
    <div className="centering-action-button" onClick={onChangeFooterModal}>
      <img class="" src={ActionButtonLogo} alt="action button" />
    </div>
  );
};

export default ActionButton;

import React from "react";
import { useSpring, animated } from "react-spring";

const Drawer = ({
  eventButton,
  show,
  eventMenu1,
  eventMenu2,
  eventMenu3,
  eventMenu4,
  user_id,
}) => {
  const props = useSpring({
    left: show ? 0 : -1500,
  });

  return (
    <animated.div
      style={props}
      className="top-menu-mobile-container"
      onClick={eventButton}
    >
      <div className="top-menu-mobile-frame">
        {user_id === null && (
          <div className="top-menu-mobile-item" onClick={eventMenu1}>
            Daftar
          </div>
        )}

        <div className="top-menu-mobile-item" onClick={eventMenu2}>
          Exhibition Hall
        </div>
        <div className="top-menu-mobile-item" onClick={eventMenu3}>
          Zoominar
        </div>
        <div className="top-menu-mobile-item" onClick={eventMenu4}>
          Kontak Kami
        </div>
      </div>
    </animated.div>
  );
};

export default Drawer;

import axios from "axios";

import {
  FETCH_MASUKKAN_ATTRIBTUTE_REQUEST,
  FETCH_MASUKKAN_ATTRIBTUTE_SUCCESS,
  FETCH_MASUKKAN_ATTRIBTUTE_FAILURE,
} from "./masukkanAttributeTypes";

export const fetchMasukkanAttributeRequest = () => {
  return {
    type: FETCH_MASUKKAN_ATTRIBTUTE_REQUEST,
  };
};

export const fetchMasukkanAttributeSuccess = (data) => {
  return {
    type: FETCH_MASUKKAN_ATTRIBTUTE_SUCCESS,
    payload: data,
  };
};

export const fetchMasukkanAttributeFailure = (error) => {
  return {
    type: FETCH_MASUKKAN_ATTRIBTUTE_FAILURE,
    payload: error,
  };
};

export const fetchMasukkanAttribute = () => {
  return (dispatch) => {
    dispatch(fetchMasukkanAttributeRequest);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_MASUKKAN_ATTRIBUTE}`,
      validateStatus: () => true,
    })
      .then((response) => {
        const masukkan = response.data;

        dispatch(fetchMasukkanAttributeSuccess(masukkan));
      })
      .catch((errors) => {
        dispatch(fetchMasukkanAttributeFailure(errors));
      });
  };
};
